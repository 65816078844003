import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Form,
  Modal,
  Tag,
  Tooltip,
} from 'antd';
import { filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import {
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  OUTFLOW_DESCRIPTION,
  PAYMENT_STATUS,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  PLAN_NAMES,
  PLAN_NAMES_OPTIONS,
  PLAN_STATUS,
  PLAN_TYPES,
  PLAN_TYPES_OPTIONS,
  ROUTES,
  SORT,
  TRANSACTION_HISTORY_TYPES,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import ModalComponent from '../../../../../components/ModalComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import {
  CREATE_REFUND_REQUEST,
  UPDATE_REFUND_REQUEST,
} from '../graphql/Mutations';
import { TRANSACTION_HISTORIES_OUTFLOW } from '../graphql/Queries';
import RefundReqModal from './RefundReqModal';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialOutflowFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialOutflowSort = {
  field: 'createdAt',
  order: 'DESC',
};

const transactionTypes = [
  TRANSACTION_HISTORY_TYPES?.REFUND_DELIVERY_CHARGED?.value,
  TRANSACTION_HISTORY_TYPES?.REFUND_REQUEST?.value,
  TRANSACTION_HISTORY_TYPES?.SECURITY_DEPOSIT?.value,
];

const OutflowTable = ({
  isOutflowModalOpen,
  setIsOutflowModalOpen,
  isEditMode,
  setIsEditMode,
  exportFilter,
  setExportFilter,
}) => {
  const {
    state: {
      permissions,
      commonPermissions: { isUserPermission },
    },
  } = useContext(AppContext);
  const [refundForm] = Form.useForm();
  const history = useHistory();
  const [outflowList, setOutflowList] = useState([]);
  const [outflowSearchTerm, setOutflowSearchTerm] = useState('');
  const [isEmptyOutflowList, setIsEmptyOutflowList] = useState(false);
  const [outflow, setOutflow] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isRefundBtnLoading, setIsRefundBtnLoading] = useState(false);
  const [isResolveLoading, setIsResolveLoading] = useState(false);
  const [isResolvePrompts, setIsResolvePrompts] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);

  const [isOutflowLoading, setIsOutflowLoading] = useState(true);
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState({});
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState({});
  const [finalFilterTags, setFinalFilterTags] = useState(null);

  const [transactionHistories] = useLazyQuery(TRANSACTION_HISTORIES_OUTFLOW, {
    onCompleted: (response) => {
      setOutflowList([...response?.transactionHistories?.data]);
      if (
        response?.transactionHistories?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyOutflowList(true);
      } else {
        setIsEmptyOutflowList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.transactionHistories?.count,
      };
      setPaginationProp(pagination);
      setIsOutflowLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [createRefundRequest] = useMutation(CREATE_REFUND_REQUEST, {
    onError: () => {},
  });
  const [updateRefundRequest] = useMutation(UPDATE_REFUND_REQUEST, {
    onError: () => {},
  });

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOutflowLoading(true);
    transactionHistories({
      variables: {
        filter: {
          ...initialOutflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: outflowSearchTerm,
          transactionTypes,
        },
        sort: initialOutflowSort,
      },
    });
  }, []);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsOutflowLoading(true);
    setSortedInfo(sorter);
    transactionHistories({
      variables: {
        filter: {
          ...initialOutflowFilter,
          skip,
          limit: pagination?.pageSize,
          search: outflowSearchTerm,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          ...(finalWhereFilters?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: finalWhereFilters?.subscriptionStatus,
          }),
          paidOnDateRange: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          transactionTypes:
            finalWhereFilters?.description?.flat()?.length > 0
              ? finalWhereFilters?.description?.flat()
              : transactionTypes,
          types:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          names:
            finalWhereFilters?.planSubscribed?.length > 0
              ? finalWhereFilters?.planSubscribed
              : undefined,
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOutflowSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setOutflowSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsOutflowLoading(true);
    transactionHistories({
      variables: {
        filter: {
          ...initialOutflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          ...(finalWhereFilters?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: finalWhereFilters?.subscriptionStatus,
          }),
          paidOnDateRange: {
            from: finalWhereFilters?.paidOn?.from?.$d,
            to: finalWhereFilters?.paidOn?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          transactionTypes:
            finalWhereFilters?.description?.flat()?.length > 0
              ? finalWhereFilters?.description?.flat()
              : transactionTypes,
          types:
            finalWhereFilters?.billingCycle?.length > 0
              ? finalWhereFilters?.billingCycle
              : undefined,
          names:
            finalWhereFilters?.planSubscribed?.length > 0
              ? finalWhereFilters?.planSubscribed
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOutflowSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleRefundReq = async (values) => {
    setIsRefundBtnLoading(true);
    let response;
    if (!outflow) {
      response = await createRefundRequest({
        variables: {
          where: {
            id: values?.userName,
          },
          data: {
            price: parseFloat(values?.price),
            comment: values?.comment?.trim(),
            reason: values?.reason,
          },
        },
      });
    } else {
      response = await updateRefundRequest({
        variables: {
          where: {
            id: outflow?.id,
          },
          data: {
            price: parseFloat(values?.price),
            comment: values?.comment?.trim(),
            reason: values?.reason,
          },
        },
      });
    }
    if (response?.data) {
      setIsOutflowModalOpen(false);
      setIsOutflowLoading(true);
      setOutflow({});
      transactionHistories({
        variables: {
          filter: {
            ...initialOutflowFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: outflowSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            ...(finalWhereFilters?.subscriptionStatus?.length > 0 && {
              subscriptionStatus: finalWhereFilters?.subscriptionStatus,
            }),
            paidOnDateRange: {
              from: finalWhereFilters?.paidOn?.from?.$d,
              to: finalWhereFilters?.paidOn?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            transactionTypes:
              finalWhereFilters?.description?.flat()?.length > 0
                ? finalWhereFilters?.description?.flat()
                : transactionTypes,
            types:
              finalWhereFilters?.billingCycle?.length > 0
                ? finalWhereFilters?.billingCycle
                : undefined,
            names:
              finalWhereFilters?.planSubscribed?.length > 0
                ? finalWhereFilters?.planSubscribed
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOutflowSort,
        },
      });
    }
    setIsRefundBtnLoading(false);
  };

  const handleResolve = async (id) => {
    setIsResolveLoading(true);
    const response = await updateRefundRequest({
      variables: {
        where: {
          id,
        },
        data: {
          status: PAYMENT_STATUS?.PAID?.value,
        },
      },
    });
    if (response?.data) {
      setIsResolveLoading(true);
      setIsResolvePrompts(false);
      transactionHistories({
        variables: {
          filter: {
            ...initialOutflowFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: outflowSearchTerm,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            ...(finalWhereFilters?.subscriptionStatus?.length > 0 && {
              subscriptionStatus: finalWhereFilters?.subscriptionStatus,
            }),
            paidOnDateRange: {
              from: finalWhereFilters?.paidOn?.from?.$d,
              to: finalWhereFilters?.paidOn?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            transactionTypes:
              finalWhereFilters?.description?.flat()?.length > 0
                ? finalWhereFilters?.description?.flat()
                : transactionTypes,
            types:
              finalWhereFilters?.billingCycle?.length > 0
                ? finalWhereFilters?.billingCycle
                : undefined,
            names:
              finalWhereFilters?.planSubscribed?.length > 0
                ? finalWhereFilters?.planSubscribed
                : undefined,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialOutflowSort,
        },
      });
    }
    setIsResolveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsOutflowLoading(true);
    transactionHistories({
      variables: {
        filter: {
          ...initialOutflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: outflowSearchTerm,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          ...(whereFilter?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: whereFilter?.subscriptionStatus,
          }),
          paidOnDateRange: {
            from: whereFilter?.paidOn?.from?.$d,
            to: whereFilter?.paidOn?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          transactionTypes:
            whereFilter?.description?.flat()?.length > 0
              ? whereFilter?.description?.flat()
              : transactionTypes,
          types:
            whereFilter?.billingCycle?.length > 0
              ? whereFilter?.billingCycle
              : undefined,
          names:
            whereFilter?.planSubscribed?.length > 0
              ? whereFilter?.planSubscribed
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOutflowSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsOutflowLoading(true);
    transactionHistories({
      variables: {
        filter: {
          ...initialOutflowFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: outflowSearchTerm,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          ...(whereFiltersCopy?.subscriptionStatus?.length > 0 && {
            subscriptionStatus: whereFiltersCopy?.subscriptionStatus,
          }),
          paidOnDateRange: {
            from: whereFiltersCopy?.paidOn?.from?.$d,
            to: whereFiltersCopy?.paidOn?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          transactionTypes:
            whereFiltersCopy?.description?.flat()?.length > 0
              ? whereFiltersCopy?.description?.flat()
              : transactionTypes,
          types:
            whereFiltersCopy?.billingCycle?.length > 0
              ? whereFiltersCopy?.billingCycle
              : undefined,
          names:
            whereFiltersCopy?.planSubscribed?.length > 0
              ? whereFiltersCopy?.planSubscribed
              : undefined,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialOutflowSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(PAYMENT_STATUS, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'subscriptionStatus':
          forEach(PLAN_STATUS, (item) => {
            optionsCopy?.push({ label: item?.text, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'description':
          forEach(OUTFLOW_DESCRIPTION, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'billingCycle':
          forEach(PLAN_TYPES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'planSubscribed':
          forEach(PLAN_NAMES_OPTIONS, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
    }
  }, [filterVisible]);

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt' || dataIndex === 'paidOn') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent spinning={filterLoading} setHeight={35}>
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div className="filter-checkboxes">
              {filterList?.length > 0 ? (
                map(filterList, (item) => (
                  <div className="single-checkbox-div" key={item?.key}>
                    <Checkbox
                      value={item}
                      checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                      key={item?.key || item}
                      onChange={(e) => changeFilter(e, dataIndex)}
                      className="single-checkbox"
                    >
                      <span>{item?.label}</span>
                    </Checkbox>
                  </div>
                ))
              ) : (
                <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'TRANSACTION NUMBER',
      dataIndex: 'transactionNumber',
      key: 'transactionNumber',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.transactionNumber || '-',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
    },
    {
      title: 'USER NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      onCell: (record) => ({
        onClick: () =>
          isUserPermission &&
          record?.user?.id &&
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${record?.user?.id}/${record?.user?.userNumber}`,
          ),
        className: `${
          isUserPermission && record?.user?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => {
        const userData = record?.user;
        return (
          <>
            {userData?.firstName || userData?.lastName ? (
              <div className="table-data-with-id">{`${
                userData?.firstName ? userData?.firstName : ''
              } ${userData?.lastName ? userData?.lastName : ''}`}</div>
            ) : (
              '-'
            )}
            {userData?.userNumber ? (
              <div className="font-size-12">({userData?.userNumber})</div>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      title: 'GENERATED ON',
      dataIndex: 'generatedOn',
      key: 'generatedOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR),
      ...filterPopup('createdAt'),
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => TRANSACTION_HISTORY_TYPES?.[record?.type]?.key,
      ...filterPopup('description'),
    },
    {
      title: 'BILLING CYCLE',
      dataIndex: 'billingCycle',
      key: 'billingCycle',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_TYPES?.[record?.subscription?.subscriptionPlan?.type]?.key || '-',
      ...filterPopup('billingCycle'),
    },
    {
      title: 'PLAN SUBSCRIBED',
      dataIndex: 'planSubscribed',
      key: 'planSubscribed',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_NAMES?.[record?.subscription?.subscriptionPlan?.name]?.key || '-',
      ...filterPopup('planSubscribed'),
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      key: 'amount',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => {
        const gst = record?.metaData?.taxesApplied?.percentage
          ? record?.price * (record?.metaData?.taxesApplied?.percentage / 100)
          : 0;
        return record?.price + gst;
      },
    },
    {
      title: 'PAID ON',
      dataIndex: 'paidOn',
      key: 'paidOn',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.paidOn
          ? formatDate(record?.paidOn, DATE_FORMATS?.REGULAR)
          : '-',
      ...filterPopup('paidOn'),
    },
    {
      title: 'COMMENT',
      dataIndex: 'comment',
      key: 'comment',
      ellipsis: true,
      width: 150,
      render: (_, record) =>
        record?.comment ? (
          <>
            <div className="action-button">
              <CommentIcon
                className="mr-16 pointer svg-icon"
                onClick={() => {
                  setIsCommentModalOpen(true);
                  setOutflow(record);
                }}
              />
            </div>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'CURRENT PLAN STATUS',
      dataIndex: 'planStatus',
      key: 'planStatus',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        PLAN_STATUS?.[record?.subscription?.status]?.text || '-',
      ...filterPopup('subscriptionStatus'),
    },
    {
      title: 'REFERENCE TRANSACTION NUMBER',
      dataIndex: 'referenceTransactionNumber',
      key: 'referenceTransactionNumber',
      width: 250,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.metaData?.carryForwardedTransactionData?.transactionNumber ||
        '-',
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 200,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={PAYMENT_STATUS?.[record?.status]?.color}
          text={PAYMENT_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status'),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            <Button
              type="primary"
              size="small"
              className="table-action-primary-btn"
              onClick={() => {
                setIsResolvePrompts(true);
                setOutflow(record);
              }}
              disabled={[
                PAYMENT_STATUS?.PAID?.value,
                PAYMENT_STATUS?.CARRY_FORWARDED?.value,
              ]?.includes(record?.status)}
            >
              Resolve
            </Button>
            <div className="ml-16 d-flex">
              <Tooltip
                title="Edit"
                placement="top"
                className="table-action-button"
                zIndex={4}
              >
                <Button
                  disabled={record?.status === PAYMENT_STATUS?.PAID?.value}
                  type="link"
                  onClick={() => {
                    setIsEditMode(true);
                    setIsOutflowModalOpen(true);
                    setOutflow(record);
                  }}
                >
                  <EditIcon
                    className={
                      record?.status === PAYMENT_STATUS?.PAID?.value
                        ? 'svg-icon-grey'
                        : 'svg-icon'
                    }
                  />
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (
      item?.dataIndex === 'actions' &&
      !hasPermission(
        PERMISSIONS_KEY?.PAYMENTS_END_USER_OUTFLOW,
        PERMISSION_TYPE?.UPDATE,
        permissions,
      )
    ) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyOutflowList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isResolvePrompts}
        onOk={() => handleResolve(outflow?.id)}
        onCancel={() => setIsResolvePrompts(false)}
        okButtonProps={{ loading: isResolveLoading }}
      >
        Are you sure you want to
        <strong> Resolve </strong>
        this?
      </Modal>
      <ModalComponent
        title="Comment"
        open={isCommentModalOpen}
        onCancel={() => {
          setIsCommentModalOpen(false);
        }}
        footer={null}
      >
        <Divider />
        {outflow?.comment || '-'}
      </ModalComponent>
      <RefundReqModal
        form={refundForm}
        onFinish={handleRefundReq}
        loadings={isRefundBtnLoading}
        isModalOpen={isOutflowModalOpen}
        setIsModalOpen={setIsOutflowModalOpen}
        outflow={outflow}
        setOutflow={setOutflow}
        isEditMode={isEditMode}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={outflowList || []}
        loading={isOutflowLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default OutflowTable;
