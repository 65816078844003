import { Empty } from 'antd';
import React from 'react';
import LoaderComponent from '../../../../../components/LoaderComponent';
import AllOrderCard from './AllOrderCard';

const AllOrderList = ({
  orderList,
  infiniteScrollRef,
  isFetchMoreOrderLoading,
  isEmptyOrderList,
  isOrderLoading,
  setOrder,
  isNewPath,
  setIsNotePrompts,
  setIsOrderAction,
  handleOrderChange,
  setIsAllOrderItemModalOpen,
  handleChallanDownload,
  isChallanLoading,
  handleChallanReset,
  isResetLoading,
}) => (
  <div>
    {!isEmptyOrderList &&
      orderList?.map((order) => (
        <AllOrderCard
          key={order?.id}
          order={order}
          infiniteScrollRef={infiniteScrollRef}
          setOrder={setOrder}
          isNewPath={isNewPath}
          setIsNotePrompts={setIsNotePrompts}
          setIsOrderAction={setIsOrderAction}
          handleOrderChange={handleOrderChange}
          setIsAllOrderItemModalOpen={setIsAllOrderItemModalOpen}
          handleChallanDownload={handleChallanDownload}
          isChallanLoading={isChallanLoading}
          handleChallanReset={handleChallanReset}
          isResetLoading={isResetLoading}
        />
      ))}
    {(isFetchMoreOrderLoading || isOrderLoading) && (
      <LoaderComponent size="small" setHeight={10} />
    )}
    {isEmptyOrderList && <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />}
  </div>
);

export default AllOrderList;
