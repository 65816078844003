import { Card, Image, Tag } from 'antd';
import React, { useState } from 'react';
import { DATE_FORMATS, REQUEST_STATUS, SIZE } from '../../../common/constants';
import { formatDate } from '../../../common/utils';
import CommonPreview from '../../../components/CommonPreview';

const BagRequestCard = ({ bagReq, infiniteScrollRef }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [sizeImage, setSizeImage] = useState([]);

  return (
    <>
      <CommonPreview
        visible={isVisible}
        setVisible={setIsVisible}
        image={sizeImage}
        setImage={setSizeImage}
      />
      <Card
        className="ml-12 mr-12 mb-24 mt-12 mobile-card"
        title={
          <div className="overflow-scroll no-scroll-bar">
            {bagReq?.requestNumber}
          </div>
        }
        extra={
          <Tag
            color={REQUEST_STATUS?.[bagReq?.status]?.color}
            className="ml-16"
          >
            {REQUEST_STATUS?.[bagReq?.status]?.text}
          </Tag>
        }
        ref={infiniteScrollRef}
      >
        <div className="mb-8">
          <span className="mobile-card-label">Quantity: </span>
          <span>{bagReq?.metaData?.quantity || 'No Quantity'}</span>
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Size: </span>
          {SIZE?.[bagReq?.metaData?.size] ? (
            <>
              <span className="mr-8">
                {SIZE?.[bagReq?.metaData?.size]?.label}
              </span>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={SIZE?.[bagReq?.metaData?.size]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setIsVisible(true);
                  setSizeImage(SIZE?.[bagReq?.metaData?.size]?.url);
                }}
                className="pointer"
              />
            </>
          ) : (
            'No Size'
          )}
        </div>
        <div className="mb-8">
          <span className="mobile-card-label">Request Date: </span>
          <span>
            {formatDate(bagReq?.createdAt, DATE_FORMATS?.REGULAR) ||
              'No Request Date'}
          </span>
        </div>
      </Card>
    </>
  );
};

export default BagRequestCard;
