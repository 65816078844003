import { useLazyQuery, useMutation } from '@apollo/client';
import { Col, Form, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  DATE_FORMATS,
  DEVICE_TYPE,
  PAYMENT_PROVIDERS_TYPE,
  PLAN_NAMES,
  PLAN_TYPES,
  SUBSCRIPTION_TYPE,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import LoaderComponent from '../../../../../components/LoaderComponent';
import {
  ASSIGN_SUBSCRIPTION,
  UPDATE_NON_RECURRING_SUBSCRIPTION_ADMIN,
  UPDATE_USER_SUBSCRIPTION,
} from '../graphql/Mutations';
import { USER_SUBSCRIPTION_ADMIN } from '../graphql/Queries';
import DeliveryModal from './DeliveryModal';
import ExpiryDateModal from './ExpiryDateModal';
import SubscriptionModal from './SubscriptionModal';
import UpdateSubModal from './UpdateSubModal';

const SubscriptionInfo = ({
  isDeliveryModalOpen,
  setIsDeliveryModalOpen,
  isSubscriptionModalOpen,
  setIsSubscriptionModalOpen,
  isExpiryDateModalOpen,
  setIsExpiryDateModalOpen,
  userId,
  isView,
  setIsSubscriptionBtnDisable,
  setIsExpiryDateBtnDisable,
  setIsDeliveryBtnDisable,
  isUpdatePlanModalOpen,
  setIsUpdatePlanModalOpen,
  setIsUpdatePlanBtnDisable,
  isPlanListEmpty,
  setIsPlanListEmpty,
}) => {
  const [form] = Form.useForm();
  const [endUserDetail, setEndUserDetail] = useState();
  const [isEndUserLoading, setIsEndUserLoading] = useState(true);
  const [isSubscriptionBtnLoading, setIsSubscriptionBtnLoading] = useState(
    false,
  );
  const [isExpireBtnLoading, setIsExpireBtnLoading] = useState(false);
  const [isFreeDeliveryBtnLoading, setIsFreeDeliveryBtnLoading] = useState(
    false,
  );
  const [
    isSubscriptionUpdateBtnLoading,
    setIsSubscriptionUpdateBtnLoading,
  ] = useState(false);

  const [userSubscriptionAdmin] = useLazyQuery(USER_SUBSCRIPTION_ADMIN, {
    onCompleted: (response) => {
      const data = response?.userSubscriptionAdmin?.data;
      setEndUserDetail(data);
      setIsEndUserLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setIsEndUserLoading(false);
    },
  });

  const [assignSubscription] = useMutation(ASSIGN_SUBSCRIPTION, {
    onError: () => { },
  });

  const [updateNonRecurringSubscriptionAdmin] = useMutation(
    UPDATE_NON_RECURRING_SUBSCRIPTION_ADMIN,
    {
      onError: () => { },
    },
  );

  const [updateUserSubscription] = useMutation(UPDATE_USER_SUBSCRIPTION, {
    onError: () => { },
  });

  useEffect(() => {
    setIsEndUserLoading(true);
    userSubscriptionAdmin({
      variables: {
        where: {
          id: userId,
        },
      },
    });
  }, []);

  useEffect(() => {
    if (!isView && !isEndUserLoading) {
      if (!endUserDetail) {
        setIsSubscriptionBtnDisable(false);
        setIsDeliveryBtnDisable(true);
      } else {
        setIsSubscriptionBtnDisable(true);
        setIsDeliveryBtnDisable(false);
      }

      if (
        endUserDetail?.type !== SUBSCRIPTION_TYPE?.PURCHASE?.value &&
        endUserDetail
      ) {
        setIsExpiryDateBtnDisable(false);
      } else {
        setIsExpiryDateBtnDisable(true);
      }
      if (endUserDetail?.type !== SUBSCRIPTION_TYPE?.PURCHASE?.value) {
        setIsUpdatePlanBtnDisable(false);
      }
    }
  }, [endUserDetail, isEndUserLoading]);

  const handleAssignSubscription = async (values) => {
    setIsSubscriptionBtnLoading(true);
    let transactionId;
    if (values?.modeOfPayment.toLowerCase() === 'cash') {
      transactionId = 'null';
    } else if (values?.paid === 'foc') {
      transactionId = 'NA'
    } else if (values?.transactionId && values?.transactionId !== null && values?.transactionId !== undefined) {
      transactionId = values?.transactionId;
    }
    else {
      transactionId = values?.transactionId;
    }
    const response = await assignSubscription({
      variables: {
        data: {
          pricePaid: parseFloat(values?.pricePaid),
          securityDepositPaid: parseFloat(values?.securityDepositPaid),
          membershipChargesPaid: parseFloat(values?.membershipChargesPaid),
          planId: values?.plan,
          membershipCharges: parseFloat(values?.membershipCharges),
          securityDeposit: parseFloat(values?.securityDeposit),
          priceDiscount: parseFloat(values?.price) - parseFloat(values?.pricePaid),
          securityDepositDiscount: parseFloat(values?.securityDeposit) - parseFloat(values?.securityDepositPaid),
          membershipChargesDiscount: parseFloat(values?.membershipCharges) - parseFloat(values?.membershipChargesPaid),
          payableAmount: values?.payableAmount,
          paid: values?.paid,
          note: values?.note,
          discount: values?.discount,
          modeOfPayment: values?.modeOfPayment,
          activatedAt: values?.activatedAt,
          transactionId,
        },
        where: {
          id: userId,
        },
      },
    });
    if (response?.data) {
      setIsSubscriptionBtnLoading(false);
      setIsEndUserLoading(true);
      setIsSubscriptionModalOpen(false);
      userSubscriptionAdmin({
        variables: {
          where: {
            id: userId,
          },
        },
      });
    }
    setIsSubscriptionBtnLoading(false);
  };

  const handleUpdateSubscriptionPlan = async (values) => {
    setIsSubscriptionUpdateBtnLoading(true);
    let transactionId;
    if (values?.modeOfPayment.toLowerCase() === 'cash') {
      transactionId = 'null';
    } else if (values?.paid === 'foc') {
      transactionId = 'NA'
    } else if (values?.transactionId && values?.transactionId !== null && values?.transactionId !== undefined) {
      transactionId = values?.transactionId;
    }
    else if (!values?.transactionId || values?.transactionId === null || values?.transactionId !== undefined) {
      transactionId = 'NA'
    }
    else {
      transactionId = values?.transactionId;
    }
    const response = await updateNonRecurringSubscriptionAdmin({
      variables: {
        data: {
          pricePaid: parseFloat(values?.pricePaid),
          securityDepositPaid: parseFloat(values?.securityDepositPaid),
          membershipChargesPaid: parseFloat(values?.membershipChargesPaid),
          planId: values?.plan,
          membershipCharges: parseFloat(values?.membershipCharges),
          securityDeposit: parseFloat(values?.securityDeposit),
          priceDiscount: parseFloat(values?.price) - parseFloat(values?.pricePaid),
          securityDepositDiscount: parseFloat(values?.securityDeposit) - parseFloat(values?.securityDepositPaid),
          membershipChargesDiscount: parseFloat(values?.membershipCharges) - parseFloat(values?.membershipChargesPaid),
          payableAmount: values?.payableAmount,
          paid: values?.paid,
          note: values?.note,
          discount: values?.discount,
          modeOfPayment: values?.modeOfPayment,
          activatedAt: values?.activatedAt,
          transactionId,
        },
        where: {
          id: userId,
        },
      },
    });
    if (response?.data) {
      setIsEndUserLoading(true);
      form?.resetFields();
      setIsUpdatePlanModalOpen(false);
      userSubscriptionAdmin({
        variables: {
          where: {
            id: userId,
          },
        },
      });
    }
    setIsSubscriptionUpdateBtnLoading(false);
  };

  const handleUpdateSubscription = async (values) => {
    setIsExpireBtnLoading(true);
    const response = await updateUserSubscription({
      variables: {
        data: {
          expireAt: values?.expireAt?.endOf('day'),
        },
        where: {
          id: endUserDetail?.id,
        },
      },
    });
    if (response?.data) {
      setIsExpireBtnLoading(false);
      setIsEndUserLoading(true);
      setIsExpiryDateModalOpen(false);
      userSubscriptionAdmin({
        variables: {
          where: {
            id: userId,
          },
        },
      });
    }
    setIsExpireBtnLoading(false);
  };

  const handleFreeDelivery = async (values) => {
    setIsFreeDeliveryBtnLoading(true);
    const response = await updateUserSubscription({
      variables: {
        data: {
          freeDeliveryCount: parseInt(values?.freeDeliveries, 10),
        },
        where: {
          id: endUserDetail?.id,
        },
      },
    });
    if (response?.data) {
      setIsFreeDeliveryBtnLoading(false);
      setIsEndUserLoading(true);
      setIsDeliveryModalOpen(false);
      userSubscriptionAdmin({
        variables: {
          where: {
            id: userId,
          },
        },
      });
    }
    setIsFreeDeliveryBtnLoading(false);
  };

  return (
    <>
      <DeliveryModal
        isModalOpen={isDeliveryModalOpen}
        setIsModalOpen={setIsDeliveryModalOpen}
        loadings={isFreeDeliveryBtnLoading}
        onFinish={handleFreeDelivery}
        endUserDetail={endUserDetail}
      />
      <SubscriptionModal
        isModalOpen={isSubscriptionModalOpen}
        setIsModalOpen={setIsSubscriptionModalOpen}
        loadings={isSubscriptionBtnLoading}
        onFinish={handleAssignSubscription}
      />
      <ExpiryDateModal
        isModalOpen={isExpiryDateModalOpen}
        setIsModalOpen={setIsExpiryDateModalOpen}
        loadings={isExpireBtnLoading}
        onFinish={handleUpdateSubscription}
        endUserDetail={endUserDetail}
      />
      <UpdateSubModal
        form={form}
        isModalOpen={isUpdatePlanModalOpen}
        setIsModalOpen={setIsUpdatePlanModalOpen}
        loadings={isSubscriptionUpdateBtnLoading}
        onFinish={handleUpdateSubscriptionPlan}
        endUserDetail={endUserDetail}
        isPlanListEmpty={isPlanListEmpty}
        setIsPlanListEmpty={setIsPlanListEmpty}
      />
      {isEndUserLoading ? (
        <LoaderComponent
          size="large"
          setHeight="60"
          spinning={isEndUserLoading}
        />
      ) : (
        <>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Subscription Id</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.subscriptionId || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Plan subscribed</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.subscriptionPlan?.name ? (
                  <>
                    {PLAN_NAMES?.[endUserDetail.subscriptionPlan.name]?.key} /
                    {PLAN_TYPES?.[endUserDetail.subscriptionPlan.type]?.key}
                  </>
                ) : (
                  '-'
                )}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Total Free deliveries Per Month
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.userSubscriptionMonthlyRestrictions?.[0]
                  ?.subscriptionFeatures?.totalFreeDeliveryPerMonth || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Available Free deliveries
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.userSubscriptionMonthlyRestrictions?.[0]
                  ?.availableFreeDeliveriesCount || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Used free deliveries
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.userSubscriptionMonthlyRestrictions?.[0]
                  ?.usedFreeDeliveriesCount || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Bonus free deliveries
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.subscriptionPlan?.subscriptionFeatures
                  ?.bonusDeliveries || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Used Bonus free deliveries
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.userSubscriptionRenewals?.[0]
                  ?.usedBonusDeliveriesCount || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Expire At</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.expireAt
                  ? formatDate(endUserDetail?.expireAt, DATE_FORMATS?.REGULAR)
                  : '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Type</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {SUBSCRIPTION_TYPE?.[endUserDetail?.type]?.label || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Payable Amount</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.metaData?.amount || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Note</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.metaData?.note || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Payment Provider</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {PAYMENT_PROVIDERS_TYPE?.[
                  endUserDetail?.metaData?.paymentProvider
                ]?.label || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Device Type</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {DEVICE_TYPE?.[endUserDetail?.metaData?.deviceType]?.label ||
                  '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Membership fee collected
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.subscriptionFeatures?.memberShipFeesPaid === null
                  ?
                  endUserDetail?.userSubscriptionMonthlyRestrictions[0]
                    ?.subscriptionFeatures?.memberShipFees
                  : endUserDetail?.subscriptionFeatures?.memberShipFeesPaid
                }

              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Security deposit collected
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.subscriptionFeatures?.refundableDepositPaid === null
                  ? endUserDetail?.userSubscriptionMonthlyRestrictions[0]
                    ?.subscriptionFeatures?.refundableDeposit
                  : endUserDetail?.subscriptionFeatures?.refundableDepositPaid
                }
                {/* {endUserDetail?.userSubscriptionMonthlyRestrictions[0]
                  ?.subscriptionFeatures?.refundableDeposit || '-'} */}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">
                Total Discount
              </span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.subscriptionFeatures?.refundableDepositPaid === null
                  ? endUserDetail?.userSubscriptionMonthlyRestrictions[0]
                    ?.subscriptionFeatures?.refundableDepositDiscountAdmin
                  : parseInt(endUserDetail?.subscriptionFeatures?.refundableDepositDiscountAdmin || 0, 10) +
                  parseInt(endUserDetail?.subscriptionFeatures?.membershipDiscountAdmin || 0, 10) +
                  parseInt(endUserDetail?.subscriptionFeatures?.planPriceDiscountAdmin || 0, 10)
                }
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Payment mode</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.method || '-'}
              </span>
            </Col>
          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Payment date</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.startedAt
                  ? formatDate(endUserDetail?.startedAt, DATE_FORMATS?.REGULAR)
                  : '-'}
              </span>
            </Col>

          </Row>
          <Row className="mb-16" gutter={32}>
            <Col xs={12} sm={12} md={12} lg={8} xl={6} xxl={6}>
              <span className="subscription-tab-text">Transaction Id</span>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={8}
              xl={6}
              xxl={6}
              className="text-right"
            >
              <span className="subscription-tab-text-value">
                {endUserDetail?.transactionId
                  ? endUserDetail?.transactionId
                  : '-'}
              </span>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default SubscriptionInfo;
