import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Image,
  Modal,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  ROUTES,
  SORT,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonPreviewTable from '../../../../../components/CommonPreviewTable';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { UPDATE_TOY } from '../graphql/Mutations';
import {
  AGE_GROUPS,
  BRANDS_ADMIN,
  CATEGORIES,
  FACILITATES_ADMIN,
  GENRES_ADMIN,
  MATERIALS_ADMIN,
  TAGS_ADMIN,
  TOYS,
} from '../graphql/Queries';

const { RangePicker } = DatePicker;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialToyFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialToySort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'name',
  order: 'ASC',
};

let filterScrollDebounceJob = null;

const ToyTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [toyList, setToyList] = useState([]);
  const [toySearchTerm, setToySearchTerm] = useState('');
  const [isEmptyToyList, setIsEmptyToyList] = useState(false);
  const [toy, setToy] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isArchivePrompts, setIsArchivePrompts] = useState(false);

  const [isToyLoading, setIsToyLoading] = useState(true);
  // const [isGenreLoading, setIsGenreLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);

  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [visible, setVisible] = useState(false);
  const [toyImages, setToyImages] = useState([]);

  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  // const [filterGenres, setFilterGenres] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [updateToy] = useMutation(UPDATE_TOY, {
    onError: () => {},
  });

  const [toys] = useLazyQuery(TOYS, {
    onCompleted: (response) => {
      setToyList([...response?.toys?.data]);
      if (response?.toys?.count === 0 && initialPaginationValue?.total === 0) {
        setIsEmptyToyList(true);
      } else {
        setIsEmptyToyList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.toys?.count,
      };
      setPaginationProp(pagination);
      setIsToyLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [brandsAdmin, { loading: isBrandsLoading }] = useLazyQuery(
    BRANDS_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.brandsAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.brandsAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.brandsAdmin?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [categories, { loading: isCategoriesLoading }] = useLazyQuery(
    CATEGORIES,
    {
      onCompleted: (response) => {
        if (response?.categories?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.categories?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.categories?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [facilitatesAdmin, { loading: isFacilitateLoading }] = useLazyQuery(
    FACILITATES_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.facilitatesAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.facilitatesAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.facilitatesAdmin?.data, (item) =>
          optionsCopy?.push({
            label: item?.name?.replace(/\s/g, '\u00a0'),
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [tagsAdmin, { loading: isTagsLoading }] = useLazyQuery(TAGS_ADMIN, {
    onCompleted: (response) => {
      if (response?.tagsAdmin?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.tagsAdmin?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.tagsAdmin?.data, (item) =>
        optionsCopy?.push({
          label: item?.name?.replace(/\s/g, '\u00a0'),
          key: item?.id,
        }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });
  const [genresAdmin, { loading: isGenresLoading }] = useLazyQuery(
    GENRES_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.genresAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.genresAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.genresAdmin?.data, (item) =>
          optionsCopy?.push({
            label: item?.name?.replace(/\s/g, '\u00a0'),
            key: item?.id,
          }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [materialsAdmin, { loading: isMaterialsLoading }] = useLazyQuery(
    MATERIALS_ADMIN,
    {
      onCompleted: (response) => {
        if (response?.materialsAdmin?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.materialsAdmin?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.materialsAdmin?.data, (item) =>
          optionsCopy?.push({ label: item?.name, key: item?.id }),
        );
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const [ageGroups, { loading: isAgeGroupsLoading }] = useLazyQuery(
    AGE_GROUPS,
    {
      onCompleted: (response) => {
        if (response?.ageGroups?.data?.length < LIMIT) {
          setFilterIsEnd(true);
        }
        if (response?.ageGroups?.count === 0) {
          setIsEmptyFilterList(true);
        } else {
          setIsEmptyFilterList(false);
        }
        const optionsCopy = [];
        forEach(response?.ageGroups?.data, (item) => {
          if (item?.minAge !== 0 || item?.maxAge !== 0) {
            optionsCopy?.push({
              label:
                item?.minAge > 18
                  ? `${Math.floor(item?.minAge / 12)} - ${Math.floor(
                      item?.maxAge / 12,
                    )} Years`
                  : `${item?.minAge} - ${item?.maxAge} Months`,
              key: item?.id,
            });
          }
        });
        setFilterList([...filterList, ...optionsCopy]);
        setFilterLoading(false);
      },
      fetchPolicy: 'network-only',
      onError() {
        setFilterLoading(false);
      },
    },
  );

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.MASTER_LIST_TOY,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsToyLoading(true);
    toys({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: toySearchTerm,
          isArchived: isArchiveFilter,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
          genreIds: finalWhereFilters?.genres,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setExportFilter({ ...exportFilter, isArchived: isArchiveFilter });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsToyLoading(true);
    setSortedInfo(sorter);
    toys({
      variables: {
        filter: {
          ...initialToyFilter,
          skip,
          limit: pagination?.pageSize,
          search: toySearchTerm,
          isArchived: isArchiveFilter,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
          genreIds: finalWhereFilters?.genreIds,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setToySearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsToyLoading(true);
    toys({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          brandIds: finalWhereFilters?.brand,
          categoryIds: finalWhereFilters?.category,
          facilitateIds: finalWhereFilters?.facilitates,
          tagIds: finalWhereFilters?.tags,
          genreIds: finalWhereFilters?.genre,
          materialIds: finalWhereFilters?.material,
          ageGroupIds: finalWhereFilters?.ageGroup,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleArchive = async (id, isArchived, images) => {
    setIsArchiveLoading(true);
    const response = await updateToy({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
          images,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsToyLoading(true);
      setIsArchivePrompts(false);
      toys({
        variables: {
          filter: {
            ...initialToyFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: toySearchTerm,
            isArchived: isArchiveFilter,
            brandIds: finalWhereFilters?.brand,
            categoryIds: finalWhereFilters?.category,
            facilitateIds: finalWhereFilters?.facilitates,
            tagIds: finalWhereFilters?.tags,
            genreIds: finalWhereFilters?.genre,
            materialIds: finalWhereFilters?.material,
            ageGroupIds: finalWhereFilters?.ageGroup,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialToySort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsToyLoading(true);
    toys({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: toySearchTerm,
          isArchived: isArchiveFilter,
          brandIds: whereFilter?.brand,
          categoryIds: whereFilter?.category,
          facilitateIds: whereFilter?.facilitates,
          tagIds: whereFilter?.tags,
          genreIds: whereFilter?.genre,
          materialIds: whereFilter?.material,
          ageGroupIds: whereFilter?.ageGroup,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    const whereFiltersCopy = {
      ...whereFilter,
      [dataIndex]: [],
    };
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsToyLoading(true);
    toys({
      variables: {
        filter: {
          ...initialToyFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: toySearchTerm,
          isArchived: isArchiveFilter,
          brandIds: whereFiltersCopy?.brand,
          categoryIds: whereFiltersCopy?.category,
          facilitateIds: whereFiltersCopy?.facilitates,
          tagIds: whereFiltersCopy?.tags,
          genreIds: whereFiltersCopy?.genre,
          materialIds: whereFiltersCopy?.material,
          ageGroupIds: whereFiltersCopy?.ageGroup,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialToySort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      switch (filterIndex) {
        case 'brand':
          brandsAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'category':
          categories({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'facilitates':
          facilitatesAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'tags':
          tagsAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;
        // case 'genre':
        //   genresAdmin({
        //     variables: {
        //       filter: initialTableFilter,
        //       sort: initialTableSort,
        //     },
        //   });
        //   break;
        case 'material':
          materialsAdmin({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        case 'ageGroup':
          ageGroups({
            variables: {
              variables: {
                filter: initialTableFilter,
                sort: {
                  field: 'minAge',
                  order: SORT?.ASC,
                },
              },
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (
      !isBrandsLoading &&
      !isCategoriesLoading &&
      !isMaterialsLoading &&
      !isAgeGroupsLoading &&
      !isFacilitateLoading &&
      !isTagsLoading &&
      !isGenresLoading &&
      !filterIsEnd
    ) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'brand':
              brandsAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'category':
              categories({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'facilitates':
              facilitatesAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'tags':
              tagsAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;
            case 'genre':
              genresAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;
            case 'material':
              materialsAdmin({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            case 'ageGroup':
              ageGroups({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength + 1,
                    search: filterSearch,
                  },
                  sort: {
                    field: 'minAge',
                    order: SORT?.ASC,
                  },
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'brand':
        brandsAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'category':
        categories({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'facilitates':
        facilitatesAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'tags':
        tagsAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;
      case 'genre':
        genresAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;
      case 'material':
        materialsAdmin({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      case 'ageGroup':
        ageGroups({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: {
              field: 'minAge',
              order: SORT?.ASC,
            },
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (isVisible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(isVisible);
    },
  });

  const columns = [
    {
      title: 'PRODUCT NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.name}</div>
          <div className="font-size-12">({record?.toyNumber})</div>
        </>
      ),
    },
    {
      title: 'IMAGES',
      dataIndex: 'images',
      key: 'images',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.images?.length > 0 ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(record?.images);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Images</span>
          )}
          {record?.images?.length > 1 ? ` + ${record?.images?.length - 1}` : ''}
        </span>
      ),
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'BRAND',
      dataIndex: 'brand',
      key: 'brand',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.brand?.name,
      ...filterPopup('brand'),
    },
    {
      title: 'MATERIAL',
      dataIndex: 'material',
      key: 'material',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.material?.name,
      ...filterPopup('material'),
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      key: 'category',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.categories?.map((category) => (
          <Tag key={category?.id}>{category?.name}</Tag>
        )),
      ...filterPopup('category'),
    },
    {
      title: 'AGE GROUPS',
      dataIndex: 'ageGroups',
      key: 'ageGroups',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.ageGroups?.map((ageGroup) =>
          ageGroup?.minAge > 18 ? (
            <Tag key={ageGroup?.id}>
              {Math.floor(ageGroup?.minAge / 12)} -{' '}
              {Math.floor(ageGroup?.maxAge / 12)} Years
            </Tag>
          ) : (
            <Tag key={ageGroup?.id}>
              {ageGroup?.minAge} - {ageGroup?.maxAge} Months
            </Tag>
          ),
        ),
      ...filterPopup('ageGroup', false),
    },
    {
      title: 'FACILITATES',
      dataIndex: 'facilitates',
      key: 'facilitates',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.facilitates?.length > 0
          ? record?.facilitates?.map((facilitate) => (
              <Tag key={facilitate?.id}>
                {facilitate?.name?.replace(/\s/g, '\u00a0')}
              </Tag>
            ))
          : '-',
      ...filterPopup('facilitates'),
    },
    {
      title: 'TAGS',
      dataIndex: 'tags',
      key: 'tags',
      width: 300,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.tags?.length > 0
          ? record?.tags?.map((tag) => (
              <Tag key={tag?.id}>{tag?.name?.replace(/\s/g, '\u00a0')}</Tag>
            ))
          : '-',
      ...filterPopup('tags'),
    },
    // {
    //   title: 'GENRES',
    //   dataIndex: 'genre',
    //   key: 'genre',
    //   width: 300,
    //   align: 'left',
    //   className: 'max-width-column',
    //   render: (_, record) =>
    //     record?.genres?.length > 0
    //       ? record?.genres?.map((genre) => (
    //           <Tag key={genre?.id}>{genre?.name?.replace(/\s/g, '\u00a0')}</Tag>
    //         ))
    //       : '-',
    //   ...filterPopup('genre'),
    // },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsArchivePrompts(true);
                    setToy(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      history?.push(
                        `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/edit/${record?.id}`,
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                <Tooltip title="Archive" placement="top">
                  <ArchiveIcon
                    onClick={(e) => {
                      e?.stopPropagation();
                      setIsArchivePrompts(true);
                      setToy(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyToyList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() =>
          handleArchive(
            toy?.id,
            toy?.isArchived,
            toy?.images?.map((image) => ({
              contentType: image?.contentType,
              key: image?.key,
              order: image?.order,
              size: image?.size,
            })),
          )
        }
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!toy?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this toy?
      </Modal>
      <CommonPreviewTable
        visible={visible}
        setVisible={setVisible}
        images={toyImages}
        setImages={setToyImages}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={toyList || []}
        loading={isToyLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        rowClassName="pointer table-cell"
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.MASTER_LISTS}${ROUTES?.TOY}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default ToyTable;
