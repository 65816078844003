import React, { useContext } from 'react';
import { AppContext } from '../../../../../AppContext';
import {
  CANCELLATION_TYPE,
  DATE_FORMATS,
  USER_ROLES,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';

const NoteModal = ({
  note,
  isModalOpen,
  setIsModalOpen,
  isOrderAction,
  adminNote,
}) => {
  const { getCurrentUser } = useContext(AppContext);
  const { roles } = getCurrentUser() || {};

  return (
    <ModalComponent
      open={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      footer={null}
    >
      {note && (
        <>
          {note?.type === CANCELLATION_TYPE?.LIBRARY && (
            <div className="mb-8">
              <b className="font-size-16">Comment By Library</b>
            </div>
          )}
          {note?.type === CANCELLATION_TYPE?.USER &&
            !roles?.includes(USER_ROLES?.LIBRARIAN?.key) && (
              <div className="mb-8">
                <b className="font-size-16">Cancelled By End-user</b>
              </div>
            )}
          {note?.type === CANCELLATION_TYPE?.ADMIN && (
            <div className="mb-8">
              <b className="font-size-16">Cancelled By Admin</b>
            </div>
          )}
          {note?.date && (
            <div className="font-size-16">
              Date: {formatDate(note?.date, DATE_FORMATS?.REGULAR)}
            </div>
          )}
          {(note?.type === CANCELLATION_TYPE?.API ||
            note?.type === CANCELLATION_TYPE?.QUEUE ||
            note?.type === CANCELLATION_TYPE?.PAYMENT) &&
            !roles?.includes(USER_ROLES?.LIBRARIAN?.key) && (
              <div className="font-size-16">Reason: {note?.reason || '-'}</div>
            )}
          {(note?.type === CANCELLATION_TYPE?.LIBRARY ||
            note?.type === CANCELLATION_TYPE?.ADMIN) &&
            isOrderAction && (
              <>
                <div className="font-size-16">
                  Reason for cancellation: {note?.reason || '-'}
                </div>
                <div className="font-size-16 text-justify">
                  Additional comment: {note?.additionalComment || '-'}
                </div>
              </>
            )}
          {note?.type === CANCELLATION_TYPE?.LIBRARY && !isOrderAction && (
            <>
              <div className="font-size-16">
                Reason for not fulfilling the order: {note?.reason || '-'}
              </div>
              <div className="font-size-16 text-justify">
                Additional comment: {note?.additionalComment || '-'}
              </div>
            </>
          )}
        </>
      )}
      {adminNote && !roles?.includes(USER_ROLES?.LIBRARIAN?.key) && (
        <div className={note ? 'mt-16' : 'mt-8'}>
          <div className="mb-8">
            <b className="font-size-16">Resolving comment by admin</b>
          </div>
          <div className="font-size-16">{adminNote || '-'}</div>
        </div>
      )}
    </ModalComponent>
  );
};

export default NoteModal;
