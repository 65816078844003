import { InboxOutlined } from '@ant-design/icons';
import { Divider, Form, message, Upload } from 'antd';
import React, { useState } from 'react';
import { ALLOW_CSV_FILE } from '../../../../../common/constants';
import ModalComponent from '../../../../../components/ModalComponent';
import ToySelect from './ToySelect';

const { Item } = Form;
const { Dragger } = Upload;

const AddToysModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  libraryId,
  toyFile,
  setToyFile,
  toySelectValue,
  setToySelectValue,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleChange = (info) => {
    const {
      file: { name: fileName = '', key = '' },
    } = info;
    const ext = fileName?.substring(fileName?.lastIndexOf('.') + 1);
    const extKey = key?.substring(key?.lastIndexOf('.') + 1);
    if (ALLOW_CSV_FILE?.includes(ext || extKey)) {
      form?.setFieldsValue({
        toyFile: info?.fileList,
      });
      setToyFile(info?.fileList);
    } else {
      setToyFile(info?.fileList?.slice(0, -1));
      form?.setFieldsValue({
        toyFile: info?.fileList?.slice(0, -1),
      });
      message?.destroy();
      message?.error(`${info?.file?.name} file is not csv file.`);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setToyFile([]);
    setToySelectValue([]);
    form?.resetFields();
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Add Toys to Library"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        okButtonProps={{
          disabled: toyFile?.length === 0 && toySelectValue?.length === 0,
        }}
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item name="toyFile">
              <Dragger
                maxCount={1}
                fileList={toyFile}
                beforeUpload={() => false}
                onChange={handleChange}
                className={`${toyFile?.length > 0 ? 'hide-toy-dragger' : ''}`}
                disabled={toySelectValue?.length > 0}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag toy csv file to this area to upload
                </p>
              </Dragger>
            </Item>
            <div className="or-container mb-16 mt-16">
              <div />
              OR
              <div />
            </div>
            <Item label="Toys" name="toys">
              <ToySelect
                libraryId={libraryId}
                disabled={toyFile?.length > 0}
                onChange={(values) => setToySelectValue(values)}
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default AddToysModal;
