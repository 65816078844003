import { useLazyQuery, useMutation } from '@apollo/client';
import { Badge, Form, Image, Modal, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as ActiveIcon } from '../../../../assets/svg/circle-check-solid.svg';
import { ReactComponent as InactiveIcon } from '../../../../assets/svg/circle-xmark-solid.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE, BREAKPOINTS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE, PUBLISH_STATE,
  PUBLISH_STATUS,
  ROUTES,
  SORT,
} from '../../../../common/constants';
import {
  fileUpload,
  hasPermission,
  openNotification,
} from '../../../../common/utils';
import CommonPreview from '../../../../components/CommonPreview';
import CommonTable from '../../../../components/CommonTable';
import SearchComponent from '../../../../components/SearchComponent';
import { CREATE_GENRE, UPDATE_GENRE } from '../graphql/Mutations';
import { GENRES_ADMIN, GET_GENRE_IMAGE_SIGNED_PUT_URL } from '../graphql/Queries';
import GenresModal from './GenresModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'name',
  order: 'ASC',
};

const GenresTable = ({
  isGenresModalOpen,
  setIsGenresModalOpen,
  genresImages,
  setGenresImages,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [genresList, setGenresList] = useState([]);
  const [genresSearchTerm, setGenresSearchTerm] = useState('');
  const [isEmptyGenresList, setIsEmptyGenresList] = useState(false);
  const [genres, setGenres] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isActivePrompts, setIsActivePrompts] = useState(false);
  const [isGenresLoading, setIsGenresLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  // eslint-disable-next-line
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [genresPreviewImage, setGenresPreviewImage] = useState();
  const [toySelectValue, setToySelectValue] = useState([]);
  const [createGenre] = useMutation(CREATE_GENRE, {
    onError: () => { },
  });

  const [updateGenre] = useMutation(UPDATE_GENRE, {
    onError: () => { },
  });

  const [getGenreImageSignedPutUrl] = useLazyQuery(GET_GENRE_IMAGE_SIGNED_PUT_URL, {
    fetchPolicy: 'network-only',
    onError() {
      setIsBtnLoading(false);
    },
  });

  const [genresAdmin] = useLazyQuery(GENRES_ADMIN, {
    onCompleted: (response) => {
      setGenresList(
        [...response?.genresAdmin?.data]?.filter(
          (item) => item?.minAge !== 0 || item?.maxAge !== 0,
        ),
      );
      if (
        response?.genresAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyGenresList(true);
      } else {
        setIsEmptyGenresList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.genresAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsGenresLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.APP_CONTENT_GENRES,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsGenresLoading(true);
    genresAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: genresSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  }, [isArchiveFilter, isActiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsGenresLoading(true);
    setSortedInfo(sorter);
    genresAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: genresSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
            field: sorter?.field,
            order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setGenresSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsGenresLoading(true);
    genresAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateGenre({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsGenresLoading(true);
      setIsArchivePrompts(false);
      setGenres();
      genresAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: genresSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };
  const handlePublish = async (id, isActive) => {
    setIsActiveLoading(true);
    const response = await updateGenre({
      variables: {
        where: {
          id,
        },
        data: {
          publish: !isActive,
        },
      },
    });
    if (response?.data) {
      setIsGenresLoading(true);
      setIsActivePrompts(false);
      setIsActiveFilter(false);
      setGenres();
      genresAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: genresSearchTerm,
            publish: isActiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsActiveLoading(false);
  };

  const handleGenre = async (values) => {
    setIsBtnLoading(true);
    const uuid = uuidv4();
    let response;
    let image = '';
    if (values?.image?.length > 0 && !genresImages?.[0]?.url) {
      const { name } = values?.image?.[0];
      const ext = name?.substring(name?.lastIndexOf('.') + 1);
      const timestamp = Date?.now();
      const filename = name?.split('.')?.slice(0, -1)?.join('.');
      const newFilename = `${timestamp}_${filename}.${ext}`;
      const fileKey = `genres/${uuid}/${newFilename}`;
      const res = await getGenreImageSignedPutUrl({
        variables: {
          data: {
            fileName: fileKey,
            imageUuid: uuid,
          },
        },
      });
      if (res?.data) {
        try {
          try {
            await fileUpload(
              res?.data?.getGenreImageSignedPutUrl?.signedUrl,
              genresImages?.[0]?.originFileObj,
            );
            image = res?.data?.getGenreImageSignedPutUrl?.fileName;
          } catch (error) {
            throw new Error(
              `${genresImages?.name} upload failed. Please try again.`,
            );
          }
        } catch (error) {
          setIsBtnLoading(false);
          openNotification('error', error.message);
        }
      }
    }
    try {
      if (!genres) {
        response = await createGenre({
          variables: {
            data: {
              name: values?.name,
              imageUuid: uuid,
              image: genresImages?.[0]?.url ? undefined : image,
              order: values?.order,
              publish: false,
            },
          },
        });
      } else {
        response = await updateGenre({
          variables: {
            where: {
              id: genres?.id,
            },
            data: {
              name: values?.name,
              imageUuid: genres?.imageUuid,
              image: genresImages?.[0]?.url ? undefined : image,
              order: values?.order,
            },
          },
        });
      }
    } catch (error) {
      setIsBtnLoading(false);
      return error;
    }
    if (response?.data) {
      setIsGenresModalOpen(false);
      setIsBtnLoading(true);
      setGenres();
      form?.resetFields();
      genresAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: genresSearchTerm,
            isArchived: isArchiveFilter,
            publish: isActiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.name?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'IMAGE',
      dataIndex: 'image',
      key: 'image',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <span>
          {record?.image ? (
            <>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={record?.image}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setGenresPreviewImage(record?.image);
                }}
                className="pointer"
              />
            </>
          ) : (
            <span>No Image</span>
          )}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        <Badge
          color={PUBLISH_STATUS?.[record?.publish]?.color}
          text={PUBLISH_STATUS?.[record?.publish]?.label}
        />,
    },
    {
      title: 'ORDER',
      dataIndex: 'order',
      key: 'order',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.order || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setGenres(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsGenresModalOpen(true);
                      setGenres(record);
                      setGenresImages(
                        record?.image ? [{ url: record?.image }] : [],
                      );
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                {/* <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setGenres(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip> */}
                {!record?.publish ? <Tooltip title="Publish" placement="top" zIndex={4}>
                  <ActiveIcon
                    onClick={() => {
                      setIsActivePrompts(true);
                      setGenres(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip> : <Tooltip title="Unpublish" placement="top" zIndex={4}>
                  <InactiveIcon
                    onClick={() => {
                      setIsActivePrompts(true);
                      setGenres(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>}
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyGenresList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(genres?.id, genres?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setGenres();
          setIsActiveFilter(false);
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!genres?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this genre?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isActivePrompts}
        onOk={() => handlePublish(genres?.id, genres?.publish)}
        onCancel={() => {
          setIsActivePrompts(false);
          setGenres();
          setIsActiveFilter(false);
        }}
        okButtonProps={{ loading: isActiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!genres?.publish
            ? PUBLISH_STATE?.PUBLISH
            : PUBLISH_STATE?.UNPUBLISH}{' '}
        </strong>
        this genre?
      </Modal>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={genresPreviewImage}
        setImage={setGenresPreviewImage}
      />
      <GenresModal
        form={form}
        onFinish={handleGenre}
        loadings={isBtnLoading}
        isModalOpen={isGenresModalOpen}
        setIsModalOpen={setIsGenresModalOpen}
        genres={genres}
        setGenres={setGenres}
        genresImages={genresImages}
        setGenresImages={setGenresImages}
        setVisible={setVisible}
        setGenresPreviewImage={setGenresPreviewImage}
        toySelectValue={toySelectValue}
        setToySelectValue={setToySelectValue}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            {/* <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            /> */}
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={genresList || []}
        loading={isGenresLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.APP_CONTENT}${ROUTES?.GENRES}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default GenresTable;
