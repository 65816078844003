import { Divider, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../../../common/constants';
import { formValidatorRules } from '../../../../../common/utils';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';

const { Item } = Form;
const { positiveNumber } = formValidatorRules;
const { TextArea } = Input;

const EditInventoryModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  inventory,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [currentlyAvailableStock, setCurrentlyAvailableStock] = useState(0);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({
      totalStock:
        parseInt(currentlyAvailableStock, 10) +
          parseInt(inventory?.rentedOut, 10) || 0,
    });
  }, [currentlyAvailableStock, isModalOpen]);

  useEffect(() => {
    const initialValues = {
      ...inventory,
      totalStock:
        parseInt(inventory?.availableStock, 10) +
        parseInt(inventory?.rentedOut, 10),
      oldQuantity: inventory?.availableStock,
    };
    form.setFieldsValue(initialValues);
  }, [inventory, isModalOpen]);

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title={`Update Total Stock of ${inventory?.toy?.name}`}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Currently Available As Per System"
            name="oldQuantity"
            rules={[
              {
                required: true,
                message: 'Please Enter Currently Available As Per System!',
              },
              positiveNumber,
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Currently Available As Per System"
              isFractional={false}
              disabled
              type={NUMBER_FIELD_TYPES?.STOCK}
            />
          </Item>
          <Item
            label="Physically Available"
            name="currentlyAvailable"
            rules={[
              {
                required: true,
                message: 'Please Enter Physically Available!',
              },
              positiveNumber,
            ]}
          >
            <NumberComponent
              allowClear
              placeholder="Enter Physically Available"
              isFractional={false}
              onChange={(e) =>
                setCurrentlyAvailableStock(e?.target?.value || 0)
              }
              type={NUMBER_FIELD_TYPES?.STOCK}
            />
          </Item>
          <Item label="Rented Out" name="rentedOut" required>
            <Input allowClear placeholder="Enter Rented Out" disabled />
          </Item>
          <Item label="Total Stock" name="totalStock" required>
            <Input allowClear placeholder="Enter Total Stock" disabled />
          </Item>
          <Item name="reason" label="Reason">
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Reason"
              showCount
              maxLength={700}
            />
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default EditInventoryModal;
