import { useLazyQuery, useMutation } from '@apollo/client';
import { Badge, Form, Modal, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../AppContext';
import { ReactComponent as ActiveIcon } from '../../../../assets/svg/circle-check-solid.svg';
import { ReactComponent as InactiveIcon } from '../../../../assets/svg/circle-xmark-solid.svg';
import { ReactComponent as EditIcon } from '../../../../assets/svg/pen-to-square-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE, BREAKPOINTS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE, PUBLISH_STATE,
  PUBLISH_STATUS,
  ROUTES,
  SORT,
} from '../../../../common/constants';
import {
  hasPermission,
} from '../../../../common/utils';
import CommonPreview from '../../../../components/CommonPreview';
import CommonTable from '../../../../components/CommonTable';
import SearchComponent from '../../../../components/SearchComponent';
import { CREATE_COLLECTION, UPDATE_COLLECTION } from '../graphql/Mutations';
import { COLLECTIONS_ADMIN } from '../graphql/Queries';
import CollectionsModal from './CollectionsModal';

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialFilter = {
  limit: LIMIT,
  search: '',
  skip: 0,
};

const initialSort = {
  field: 'order',
  order: 'ASC',
};

const CollectionsTable = ({
  isCollectionsModalOpen,
  setIsCollectionsModalOpen,
}) => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const [collectionsList, setCollectionsList] = useState([]);
  const [collectionsSearchTerm, setCollectionsSearchTerm] = useState('');
  const [isEmptyCollectionsList, setIsEmptyCollectionsList] = useState(false);
  const [collections, setCollections] = useState();
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isActivePrompts, setIsActivePrompts] = useState(false);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(true);
  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isActiveLoading, setIsActiveLoading] = useState(false);
  // eslint-disable-next-line
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [isActiveFilter, setIsActiveFilter] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [collectionsPreviewImage, setCollectionsPreviewImage] = useState();
  const [toySelectValue, setToySelectValue] = useState([]);
  const [createCollection] = useMutation(CREATE_COLLECTION, {
    onError: () => { },
  });

  const [updateCollection] = useMutation(UPDATE_COLLECTION, {
    onError: () => { },
  });



  const [collectionsAdmin] = useLazyQuery(COLLECTIONS_ADMIN, {
    onCompleted: (response) => {
      setCollectionsList(
        [...response?.collectionsAdmin?.data]?.filter(
          (item) => item?.minAge !== 0 || item?.maxAge !== 0,
        ),
      );
      if (
        response?.collectionsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyCollectionsList(true);
      } else {
        setIsEmptyCollectionsList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.collectionsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsCollectionsLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() { },
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.APP_CONTENT_COLLECTION,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCollectionsLoading(true);
    collectionsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: collectionsSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  }, [isArchiveFilter, isActiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsCollectionsLoading(true);
    setSortedInfo(sorter);
    collectionsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          skip,
          limit: pagination?.pageSize,
          search: collectionsSearchTerm,
          isArchived: isArchiveFilter,
        },
        sort: sorter?.column
          ? {
            field: sorter?.field,
            order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setCollectionsSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsCollectionsLoading(true);
    collectionsAdmin({
      variables: {
        filter: {
          ...initialFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
        },
        sort: sortedInfo?.column
          ? {
            field: sortedInfo?.field,
            order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
          }
          : initialSort,
      },
    });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateCollection({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsCollectionsLoading(true);
      setIsArchivePrompts(false);
      setCollections();
      collectionsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: collectionsSearchTerm,
            isArchived: isArchiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };
  const handlePublish = async (id, isActive) => {
    setIsActiveLoading(true);
    const response = await updateCollection({
      variables: {
        where: {
          id,
        },
        data: {
          publish: !isActive,
        },
      },
    });
    if (response?.data) {
      setIsCollectionsLoading(true);
      setIsActivePrompts(false);
      setIsActiveFilter(false);
      setCollections();
      collectionsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: collectionsSearchTerm,
            publish: isActiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsActiveLoading(false);
  };

  const handleCollection = async (values) => {
    setIsBtnLoading(true);
    let response;
    try {
      if (!collections) {
        response = await createCollection({
          variables: {
            data: {
              name: values?.name,
              description: values?.description,
              shortDescription: values?.shortDescription,
              order: values?.order,
              sortingType: values?.sortingType || 'random',
              publish: false,
            },
          },
        });
      } else {
        response = await updateCollection({
          variables: {
            where: {
              id: collections?.id,
            },
            data: {
              name: values?.name,
              description: values?.description,
              shortDescription: values?.shortDescription,
              sortingType: values?.sortingType,
              order: values?.order,
            },
          },
        });
      }
    } catch (error) {
      setIsBtnLoading(false);
      return error;
    }
    if (response?.data) {
      setIsCollectionsModalOpen(false);
      setIsBtnLoading(true);
      setCollections();
      form?.resetFields();
      collectionsAdmin({
        variables: {
          filter: {
            ...initialFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: collectionsSearchTerm,
            isArchived: isArchiveFilter,
            publish: isActiveFilter,
          },
          sort: sortedInfo?.column
            ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
            : initialSort,
        },
      });
    }
    setIsBtnLoading(false);
  };

  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 250,
      align: 'left',
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'left' : false,
      className: 'max-width-column',
      render: (_, record) => record?.name?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'SHORT DESCRIPTION',
      dataIndex: 'shortDescription',
      key: 'shortDescription',
      ellipsis: true,
      width: 250,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.shortDescription?.replace(/\s/g, '\u00a0'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        <Badge
          color={PUBLISH_STATUS?.[record?.publish]?.color}
          text={PUBLISH_STATUS?.[record?.publish]?.label}
        />,
    },
    {
      title: 'ORDER',
      dataIndex: 'order',
      key: 'order',
      width: 150,
      ellipsis: true,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        record?.order || '-',
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      className: 'cursor-auto',
      onCell: () => ({
        onClick: (e) => e?.stopPropagation(),
      }),
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setCollections(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Edit" placement="top" zIndex={4}>
                  <EditIcon
                    onClick={() => {
                      setIsCollectionsModalOpen(true);
                      setCollections(record);

                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
                {/* <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setCollections(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip> */}
                {!record?.publish ? <Tooltip title="Publish" placement="top" zIndex={4}>
                  <ActiveIcon
                    onClick={() => {
                      setIsActivePrompts(true);
                      setCollections(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip> : <Tooltip title="Unpublish" placement="top" zIndex={4}>
                  <InactiveIcon
                    onClick={() => {
                      setIsActivePrompts(true);
                      setCollections(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>}
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyCollectionsList ? '' : <span />,
  };

  return (
    <>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(collections?.id, collections?.isArchived)}
        onCancel={() => {
          setIsArchivePrompts(false);
          setCollections();
          setIsActiveFilter(false);
        }}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!collections?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this collection?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isActivePrompts}
        onOk={() => handlePublish(collections?.id, collections?.publish)}
        onCancel={() => {
          setIsActivePrompts(false);
          setCollections();
          setIsActiveFilter(false);
        }}
        okButtonProps={{ loading: isActiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!collections?.publish
            ? PUBLISH_STATE?.PUBLISH
            : PUBLISH_STATE?.UNPUBLISH}{' '}
        </strong>
        this collection?
      </Modal>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={collectionsPreviewImage}
        setImage={setCollectionsPreviewImage}
      />
      <CollectionsModal
        form={form}
        onFinish={handleCollection}
        loadings={isBtnLoading}
        isModalOpen={isCollectionsModalOpen}
        setIsModalOpen={setIsCollectionsModalOpen}
        collections={collections}
        setCollections={setCollections}
        setVisible={setVisible}
        setCollectionsPreviewImage={setCollectionsPreviewImage}
        toySelectValue={toySelectValue}
        setToySelectValue={setToySelectValue}
      />
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            {/* <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            /> */}
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={collectionsList || []}
        loading={isCollectionsLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
        onRow={(record) => ({
          onClick: () => {
            history?.push(
              `${ROUTES?.APP_CONTENT}${ROUTES?.COLLECTION}/view/${record?.id}`,
            );
          },
        })}
      />
    </>
  );
};

export default CollectionsTable;
