import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const TRANSACTION_HISTORIES_INFLOW = gql`
  query TransactionHistories(
    $where: UserUniqueInput
    $filter: TransactionHistoryFilter
    $sort: TransactionHistorySort
  ) {
    transactionHistories(where: $where, filter: $filter, sort: $sort) {
      data {
        status
        user {
          lastName
          firstName
          id
          userNumber
        }
        metaData {
          taxesApplied {
            type
            percentage
          }
        }
        createdAt
        type
        transactionNumber
        subscription {
          status
          subscriptionId
          metaData {
            userDamageWaiverFeesApplied
            userTrialApplied
            membershipData {
              isMembershipFee
              amount
            }
            isSecurityDeposit
            deviceType
            paymentProvider
            updatedBy {
              type
            }
          }
          coupon {
            name
            type
            value
          }
          subscriptionPlan {
            name
            type
            price
          }
          subscriptionFeatures {
            discount
            memberShipFees
            refundableDeposit
            totalKeepCountPerMonth
            effectiveCostPerMonth
            totalFreeDeliveryPerMonth
            bonusDeliveries
            actualChargesForDamageWaiverFees
            actualChargesForSecurityDeposit
            damageWaiverFees
            damageWaiverFeesApplied
            membershipFeesApplied
            securityDepositApplied
            trialAmount
            trialApplied
            trialDays
          }
        }
        price
        id
      }
      count
    }
  }
`;

export const TRANSACTION_HISTORIES_OUTFLOW = gql`
  query TransactionHistories(
    $filter: TransactionHistoryFilter
    $sort: TransactionHistorySort
  ) {
    transactionHistories(filter: $filter, sort: $sort) {
      data {
        status
        metaData {
          carryForwardedTransactionData {
            transactionHistoryId
            transactionNumber
          }
          taxesApplied {
            type
            percentage
          }
        }
        comment
        reason
        transactionNumber
        user {
          lastName
          firstName
          id
          userNumber
        }
        createdAt
        paidOn
        type
        subscription {
          status
          subscriptionPlan {
            name
            type
            price
          }
        }
        price
        id
      }
      count
    }
  }
`;

export const USERS = gql`
  query Users($filter: UsersFilter, $sort: UsersSort) {
    users(filter: $filter, sort: $sort) {
      data {
        firstName
        id
        lastName
        userNumber
      }
    }
  }
`;

export const TRANSACTION_HISTORIES_REFERRAL = gql`
  query TransactionHistories(
    $where: UserUniqueInput
    $filter: TransactionHistoryFilter
    $sort: TransactionHistorySort
  ) {
    transactionHistories(where: $where, filter: $filter, sort: $sort) {
      data {
        status
        user {
          lastName
          firstName
          id
          userNumber
        }
        metaData {
          taxesApplied {
            type
            percentage
          }
          referredUser {
            firstName
            lastName
            id
            userNumber
          }
          referredLibrary {
            id
            libraryNumber
            name
            referralCode
          }
        }
        createdAt
        type
        transactionNumber
        price
        id
      }
      count
    }
  }
`;
