import { Badge, Button, Card, Dropdown, Tag } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as CommentIcon } from '../../../../../assets/svg/comment-dots-solid.svg';
import { ReactComponent as MoreIcon } from '../../../../../assets/svg/ellipsis-vertical-solid.svg';
import {
  ALL_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS,
  CANCELLATION_TYPE,
  DATE_FORMATS,
  FULFILLMENT_STATUS,
  NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS,
  ORDER_STATUS,
} from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';

const OrderItemCard = ({
  order,
  orderItem,
  setIsNotePrompts,
  setOrderItem,
  setIsOrderAction,
  handleOrderItemChange,
  isNewPath,
  handleStickerDownload,
  isStickerLoading,
  handleStickerReset,
  isResetStickerLoading,
  setIsContentMasterModalOpen,
}) => {
  const maxTagsToShow = 5;
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllAgeGroups, setShowAllAgeGroups] = useState(false);
  const toy = orderItem?.toy;

  const renderedCategories = showAllCategories
    ? toy?.categories
    : toy?.categories?.slice(0, maxTagsToShow);

  const renderedAgeGroups = showAllAgeGroups
    ? toy?.ageGroups
    : toy?.ageGroups?.slice(0, maxTagsToShow);

  const handleMenuClick = (e) => {
    handleOrderItemChange(e?.key, orderItem, order);
  };

  const handleAllOrderItemMenuClick = () => {
    setOrderItem(orderItem);
    setIsOrderAction(false);
    setIsContentMasterModalOpen(true);
  };

  const menuProps = {
    items: NEW_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS?.map((item) => ({
      label: item?.label,
      key: item?.value,
    })),
    onClick: handleMenuClick,
    disabled:
      orderItem?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
      orderItem?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
      orderItem?.status === FULFILLMENT_STATUS?.CANCELLED?.value,
  };

  const allOrderMenuProps = {
    items: ALL_ORDER_EXPANDED_TABLE_ACTIONS_OPTIONS?.map((item) => ({
      label: item?.label,
      key: item?.value,
    })),
    onClick: handleAllOrderItemMenuClick,
  };

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 card-with-shadow"
      title={
        <div className="overflow-scroll no-scroll-bar">{toy?.toyNumber}</div>
      }
      extra={
        <div className="d-flex align-center ml-16">
          <span className="mr-16">
            {(orderItem?.status === FULFILLMENT_STATUS?.FULFILLED?.value ||
              orderItem?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
              orderItem?.status === FULFILLMENT_STATUS?.CANCELLED?.value) && (
              <Badge
                color={FULFILLMENT_STATUS?.[orderItem?.status]?.color}
                text={FULFILLMENT_STATUS?.[orderItem?.status]?.text}
              />
            )}
          </span>
          <span>
            {orderItem?.cancellationData?.type ===
              CANCELLATION_TYPE?.LIBRARY && (
              <span className="action-button">
                <CommentIcon
                  className="ml-16 mr-16 pointer svg-icon"
                  onClick={(e) => {
                    e?.stopPropagation();
                    setIsNotePrompts(true);
                    setOrderItem(orderItem);
                    setIsOrderAction(false);
                  }}
                />
              </span>
            )}
          </span>
          <Dropdown
            getPopupContainer={(trigger) => trigger?.parentNode}
            menu={isNewPath ? menuProps : allOrderMenuProps}
          >
            <MoreIcon className="pointer mobile-svg-icon" />
          </Dropdown>
        </div>
      }
    >
      <div className="mb-8">
        <span className="mobile-card-label">Product Name: </span>
        <span>{toy?.name}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Categories: </span>
        <span>
          {renderedCategories?.map((category) => (
            <Tag key={category?.id}>{category?.name}</Tag>
          ))}
          {toy?.categories?.length > maxTagsToShow && !showAllCategories && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllCategories(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Age Groups: </span>
        <span>
          {renderedAgeGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          )}
          {toy?.ageGroups?.length > maxTagsToShow && !showAllAgeGroups && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllAgeGroups(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Expected Return: </span>
        <span>
          {formatDate(orderItem?.returnDate, DATE_FORMATS?.WITH_TIME) ||
            'No Expected Return'}
        </span>
      </div>{' '}
      {!isNewPath && (
        <>
          <div className="dashed-line" />
          <div className="d-flex justify-between flex-wrap">
            <Button
              type="primary"
              className="mr-16"
              loading={isStickerLoading?.[order?.id]}
              onClick={(e) => {
                e?.stopPropagation();
                // eslint-disable-next-line no-undef
                const windowReference = window.open();
                handleStickerDownload(order?.id, windowReference);
              }}
              disabled={
                order?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
                order?.status === FULFILLMENT_STATUS?.CANCELLED?.value ||
                ![
                  ORDER_STATUS?.CONFIRMED?.value,
                  ORDER_STATUS?.READY_TO_SHIP?.value,
                ]?.includes(order?.status)
              }
            >
              Download Sticker
            </Button>
            <Button
              loading={isResetStickerLoading?.[order?.id]}
              onClick={(e) => {
                e?.stopPropagation();
                handleStickerReset(order?.id);
              }}
              disabled={
                order?.status === FULFILLMENT_STATUS?.UNFULFILLED?.value ||
                order?.status === FULFILLMENT_STATUS?.CANCELLED?.value ||
                ![
                  ORDER_STATUS?.CONFIRMED?.value,
                  ORDER_STATUS?.READY_TO_SHIP?.value,
                ]?.includes(order?.status)
              }
            >
              Reset Sticker
            </Button>
          </div>
        </>
      )}
    </Card>
  );
};

export default OrderItemCard;
