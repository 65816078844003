import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DATE_FORMATS, ROUTES, TABS } from '../../../../../common/constants';
import { formatDate } from '../../../../../common/utils';

const EndUsersTabs = (props) => {
  const history = useHistory();
  const { endUserId, endUserNumber, isView, onboardedOn, children } = props;
  const location = useLocation();
  const [activeTabKey, setActiveTabKey] = useState(TABS?.BASIC_DETAILS?.key);

  const tabList = [
    {
      key: TABS?.BASIC_DETAILS?.key,
      label: TABS?.BASIC_DETAILS?.label,
      children,
    },
    {
      key: TABS?.SUBSCRIPTION_PLAN_DETAILS?.key,
      label: TABS?.SUBSCRIPTION_PLAN_DETAILS?.label,
      children,
    },
    {
      key: TABS?.ORDER_DETAILS?.key,
      label: TABS?.ORDER_DETAILS?.label,
      children,
    },
    {
      key: TABS?.TRANSACTION_DETAILS?.key,
      label: TABS?.TRANSACTION_DETAILS?.label,
      children,
    },
  ];

  useEffect(() => {
    if (location?.pathname?.includes(ROUTES?.BASIC_DETAILS)) {
      setActiveTabKey(TABS?.BASIC_DETAILS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.SUBSCRIPTION_PLAN_DETAILS)) {
      setActiveTabKey(TABS?.SUBSCRIPTION_PLAN_DETAILS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.ORDER_DETAILS)) {
      setActiveTabKey(TABS?.ORDER_DETAILS?.key);
    }
    if (location?.pathname?.includes(ROUTES?.TRANSACTION_DETAILS)) {
      setActiveTabKey(TABS?.TRANSACTION_DETAILS?.key);
    }
  }, [location]);

  const onTabChange = (key) => {
    setActiveTabKey(key);
    switch (key) {
      case TABS?.BASIC_DETAILS?.key:
        if (!isView) {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/edit/${endUserId}/${endUserNumber}`,
          );
        } else {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.BASIC_DETAILS}/view/${endUserId}/${endUserNumber}`,
          );
        }
        break;
      case TABS?.SUBSCRIPTION_PLAN_DETAILS?.key:
        if (!isView) {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_PLAN_DETAILS}/edit/${endUserId}/${endUserNumber}`,
          );
        } else {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.SUBSCRIPTION_PLAN_DETAILS}/view/${endUserId}/${endUserNumber}`,
          );
        }
        break;
      case TABS?.ORDER_DETAILS?.key:
        if (!isView) {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.ORDER_DETAILS}/edit/${endUserId}/${endUserNumber}`,
          );
        } else {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.ORDER_DETAILS}/view/${endUserId}/${endUserNumber}`,
          );
        }
        break;
      case TABS?.TRANSACTION_DETAILS?.key:
        if (!isView) {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.TRANSACTION_DETAILS}/edit/${endUserId}/${endUserNumber}`,
          );
        } else {
          history?.push(
            `${ROUTES?.SYSTEM_USERS}${ROUTES?.END_USERS}${ROUTES?.TRANSACTION_DETAILS}/view/${endUserId}/${endUserNumber}`,
          );
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="card-container">
        <Tabs
          activeKey={activeTabKey}
          type="card"
          className="full-width"
          onChange={(key) => onTabChange(key)}
          destroyInactiveTabPane
          items={tabList}
          tabBarExtraContent={
            activeTabKey === TABS?.BASIC_DETAILS?.key ? (
              <strong>
                Onboarded On :{' '}
                {formatDate(onboardedOn, DATE_FORMATS?.ONBOARD) || '-'}
              </strong>
            ) : (
              false
            )
          }
        />
      </div>
    </>
  );
};

export default EndUsersTabs;
