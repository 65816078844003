import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Empty,
  Image,
  Modal,
  Radio,
  Switch,
  Tag,
  Tooltip,
} from 'antd';
import { debounce, filter, forEach, isEmpty, map } from 'lodash';
import { React, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../../AppContext';
import { ReactComponent as ArchiveIcon } from '../../../../../assets/svg/box-archive-solid.svg';
import { ReactComponent as ApproveIcon } from '../../../../../assets/svg/circle-check-solid.svg';
import { ReactComponent as RejectIcon } from '../../../../../assets/svg/circle-xmark-solid.svg';
import { ReactComponent as UnarchiveIcon } from '../../../../../assets/svg/right-from-bracket-solid.svg';
import {
  ARCHIVING_STATE,
  BREAKPOINTS,
  DATE_FORMATS,
  LIMIT,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
  REQUEST_STATUS,
  REQUEST_TYPES,
  ROUTES,
  SIZE,
  SORT,
  STATUS_FILTER,
} from '../../../../../common/constants';
import { formatDate, hasPermission } from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import CommonTable from '../../../../../components/CommonTable';
import LoaderComponent from '../../../../../components/LoaderComponent';
import SearchComponent from '../../../../../components/SearchComponent';
import { UPDATE_REQUEST_ADMIN } from '../../../graphql/Mutations';
import { BAGS_REQUESTS_ADMIN, LIBRARIES } from '../graphql/Queries';

const { RangePicker } = DatePicker;
const { Group } = Radio;

const initialPaginationValue = {
  total: 0,
  current: 1,
  pageSize: 10,
};

const initialBagsFilter = {
  limit: 10,
  search: '',
  skip: 0,
};

const initialBagsSort = {
  field: 'createdAt',
  order: 'DESC',
};

const initialTableFilter = {
  skip: 0,
  limit: LIMIT,
  search: '',
};

const initialTableSort = {
  field: 'createdAt',
  order: 'DESC',
};

let filterScrollDebounceJob = null;

const BagsTable = ({ exportFilter, setExportFilter }) => {
  const {
    state: {
      permissions,
      commonPermissions: { isLibraryPermission },
    },
  } = useContext(AppContext);
  const history = useHistory();
  const [bagReqList, setBagReqList] = useState([]);
  const [bagReqSearchTerm, setBagReqSearchTerm] = useState('');
  const [isEmptyBagReqList, setIsEmptyBagReqList] = useState(false);
  const [bagReq, setBagReq] = useState({});
  const [query, setQuery] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const [isArchivePrompts, setIsArchivePrompts] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isApprovePrompts, setIsApprovePrompts] = useState(false);
  const [isRejectLoading, setIsRejectLoading] = useState(false);
  const [isRejectPrompts, setIsRejectPrompts] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [sizeImage, setSizeImage] = useState([]);

  const [isBagReqLoading, setIsBagReqLoading] = useState(true);
  const [isArchiveFilter, setIsArchiveFilter] = useState(false);
  const [filterSearch, setFilterSearch] = useState('');
  const [filterList, setFilterList] = useState([]);
  const [whereFilter, setWhereFilter] = useState();
  const [filterLoading, setFilterLoading] = useState(false);
  const [filterTags, setFilterTags] = useState(null);
  const [filterVisible, setFilterVisible] = useState(false);
  const [filterIndex, setFilterIndex] = useState(null);
  const [finalWhereFilters, setFinalWhereFilters] = useState();
  const [finalFilterTags, setFinalFilterTags] = useState(null);
  const [filterIsEnd, setFilterIsEnd] = useState(false);
  const [isEmptyFilterList, setIsEmptyFilterList] = useState(false);

  const [requestsAdmin] = useLazyQuery(BAGS_REQUESTS_ADMIN, {
    onCompleted: (response) => {
      setBagReqList([...response?.requestsAdmin?.data]);
      if (
        response?.requestsAdmin?.count === 0 &&
        initialPaginationValue?.total === 0
      ) {
        setIsEmptyBagReqList(true);
      } else {
        setIsEmptyBagReqList(false);
      }
      const pagination = {
        ...paginationProp,
        defaultPageSize: LIMIT,
        total: response?.requestsAdmin?.count,
      };
      setPaginationProp(pagination);
      setIsBagReqLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [libraries, { loading: isLibrariesLoading }] = useLazyQuery(LIBRARIES, {
    onCompleted: (response) => {
      if (response?.libraries?.data?.length < LIMIT) {
        setFilterIsEnd(true);
      }
      if (response?.libraries?.count === 0) {
        setIsEmptyFilterList(true);
      } else {
        setIsEmptyFilterList(false);
      }
      const optionsCopy = [];
      forEach(response?.libraries?.data, (item) =>
        optionsCopy?.push({ label: item?.name, key: item?.id }),
      );
      setFilterList([...filterList, ...optionsCopy]);
      setFilterLoading(false);
    },
    fetchPolicy: 'network-only',
    onError() {
      setFilterLoading(false);
    },
  });

  const [updateRequestAdmin] = useMutation(UPDATE_REQUEST_ADMIN, {
    onError: () => {},
  });

  const hasUpdatePermission = hasPermission(
    PERMISSIONS_KEY?.REQUESTS_LIBRARY_BAGS,
    PERMISSION_TYPE?.UPDATE,
    permissions,
  );

  useEffect(() => {
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsBagReqLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialBagsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: bagReqSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.BAGS,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          size: finalWhereFilters?.size,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialBagsSort,
      },
    });
    setExportFilter({ ...exportFilter, isArchived: isArchiveFilter });
  }, [isArchiveFilter]);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * (pagination?.pageSize || 0);
    setPaginationProp({ ...paginationProp, ...pagination });
    setIsBagReqLoading(true);
    setSortedInfo(sorter);
    requestsAdmin({
      variables: {
        filter: {
          ...initialBagsFilter,
          skip,
          limit: pagination?.pageSize,
          search: bagReqSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.BAGS,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          size: finalWhereFilters?.size,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sorter?.column
          ? {
              field: sorter?.field,
              order: sorter?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialBagsSort,
      },
    });
  };

  const handleSearch = (value) => {
    const trimValue = value?.trim();
    setBagReqSearchTerm(trimValue);
    setPaginationProp({ ...paginationProp, current: 1, skip: 0 });
    setIsBagReqLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialBagsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: trimValue,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.BAGS,
          dateRange: {
            from: finalWhereFilters?.createdAt?.from?.$d,
            to: finalWhereFilters?.createdAt?.to?.$d,
          },
          status:
            finalWhereFilters?.status?.length > 0
              ? finalWhereFilters?.status
              : undefined,
          size: finalWhereFilters?.size,
          libraryIds: finalWhereFilters?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialBagsSort,
      },
    });
    setExportFilter({ ...exportFilter, search: trimValue });
  };

  const handleArchive = async (id, isArchived) => {
    setIsArchiveLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          isArchived: !isArchived,
        },
      },
    });
    if (response?.data) {
      setIsArchiveLoading(false);
      setIsBagReqLoading(true);
      setIsArchivePrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialBagsFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: bagReqSearchTerm,
            isArchived: isArchiveFilter,
            type: REQUEST_TYPES?.BAGS,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            size: finalWhereFilters?.size,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialBagsSort,
        },
      });
    }
    setIsArchiveLoading(false);
  };

  const handleApprove = async (id) => {
    setIsApproveLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          status: REQUEST_STATUS?.APPROVED?.value,
        },
      },
    });
    if (response?.data) {
      setIsBagReqLoading(true);
      setIsApprovePrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialBagsFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: bagReqSearchTerm,
            isArchived: isArchiveFilter,
            type: REQUEST_TYPES?.BAGS,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            size: finalWhereFilters?.size,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialBagsSort,
        },
      });
    }
    setIsApproveLoading(false);
  };

  const handleReject = async (id) => {
    setIsRejectLoading(true);
    const response = await updateRequestAdmin({
      variables: {
        where: {
          id,
        },
        data: {
          status: REQUEST_STATUS?.REJECTED?.value,
        },
      },
    });
    if (response?.data) {
      setIsBagReqLoading(true);
      setIsRejectPrompts(false);
      requestsAdmin({
        variables: {
          filter: {
            ...initialBagsFilter,
            skip:
              (paginationProp?.current - 1) * (paginationProp?.pageSize || 0),
            limit: paginationProp?.pageSize || LIMIT,
            search: bagReqSearchTerm,
            isArchived: isArchiveFilter,
            type: REQUEST_TYPES?.BAGS,
            dateRange: {
              from: finalWhereFilters?.createdAt?.from?.$d,
              to: finalWhereFilters?.createdAt?.to?.$d,
            },
            status:
              finalWhereFilters?.status?.length > 0
                ? finalWhereFilters?.status
                : undefined,
            size: finalWhereFilters?.size,
            libraryIds: finalWhereFilters?.name,
          },
          sort: sortedInfo?.column
            ? {
                field: sortedInfo?.field,
                order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
              }
            : initialBagsSort,
        },
      });
    }
    setIsRejectLoading(false);
  };

  const getFilterData = (confirm) => {
    setIsBagReqLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialBagsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: bagReqSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.BAGS,
          dateRange: {
            from: whereFilter?.createdAt?.from?.$d,
            to: whereFilter?.createdAt?.to?.$d,
          },
          status:
            whereFilter?.status?.length > 0 ? whereFilter?.status : undefined,
          size: whereFilter?.size,
          libraryIds: whereFilter?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialBagsSort,
      },
    });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFilter });
    setExportFilter({ ...exportFilter, ...finalWhereFilters, ...whereFilter });
    setFinalFilterTags(filterTags);
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    if (confirm) {
      confirm();
    }
  };

  const handleReset = (clearFilters, dataIndex, confirm) => {
    const tagsCopy = {
      ...filterTags,
      [dataIndex]: [],
    };
    let whereFiltersCopy;
    if (dataIndex === 'size') {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: null,
      };
    } else {
      whereFiltersCopy = {
        ...whereFilter,
        [dataIndex]: [],
      };
    }
    setFilterVisible(false);
    setFinalFilterTags({ ...filterTags, ...tagsCopy });
    setFinalWhereFilters({ ...finalWhereFilters, ...whereFiltersCopy });
    setExportFilter({
      ...exportFilter,
      ...finalWhereFilters,
      ...whereFiltersCopy,
    });
    setIsBagReqLoading(true);
    requestsAdmin({
      variables: {
        filter: {
          ...initialBagsFilter,
          limit: paginationProp?.pageSize || LIMIT,
          search: bagReqSearchTerm,
          isArchived: isArchiveFilter,
          type: REQUEST_TYPES?.BAGS,
          dateRange: {
            from: whereFiltersCopy?.createdAt?.from?.$d,
            to: whereFiltersCopy?.createdAt?.to?.$d,
          },
          status:
            whereFiltersCopy?.status?.length > 0
              ? whereFiltersCopy?.status
              : undefined,
          size: whereFiltersCopy?.size,
          libraryIds: whereFiltersCopy?.name,
        },
        sort: sortedInfo?.column
          ? {
              field: sortedInfo?.field,
              order: sortedInfo?.order === 'ascend' ? SORT?.ASC : SORT?.DESC,
            }
          : initialBagsSort,
      },
    });
    setPaginationProp({ ...paginationProp, skip: 0, current: 1 });
    clearFilters();
    setFilterVisible(false);
    if (confirm) {
      confirm();
    }
  };

  useEffect(() => {
    if (filterVisible) {
      setFilterList([]);
      setFilterLoading(true);
      setIsEmptyFilterList(false);
      setFilterTags({ ...filterTags, ...finalFilterTags });
      setWhereFilter({ ...whereFilter, ...finalWhereFilters });
      setFilterIsEnd(false);
      const optionsCopy = [];

      switch (filterIndex) {
        case 'status':
          forEach(STATUS_FILTER, (item) => {
            optionsCopy?.push({ label: item?.label, key: item?.key });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'size':
          map(SIZE, (size) => {
            optionsCopy?.push({ label: size?.label, key: size?.value });
          });
          setFilterList(optionsCopy);
          setFilterLoading(false);
          break;

        case 'name':
          libraries({
            variables: {
              filter: initialTableFilter,
              sort: initialTableSort,
            },
          });
          break;

        default:
          break;
      }
    }
    if (!filterVisible) {
      setFilterTags(finalFilterTags);
      setWhereFilter(finalWhereFilters);
      setFilterSearch('');
    }
  }, [filterVisible]);

  const onFilterScroll = (event, dataIndex) => {
    if (filterScrollDebounceJob) {
      filterScrollDebounceJob?.cancel();
      filterScrollDebounceJob = null;
    }
    if (!isLibrariesLoading && !filterIsEnd) {
      const target = event?.target;
      const currentLength = filterList?.length;
      if (
        target?.scrollTop + target?.offsetHeight >=
        target?.scrollHeight - 5
      ) {
        filterScrollDebounceJob = debounce(() => {
          setFilterLoading(true);
          switch (dataIndex) {
            case 'name':
              libraries({
                variables: {
                  filter: {
                    limit: LIMIT,
                    skip: currentLength,
                    search: filterSearch,
                  },
                  sort: initialTableSort,
                },
              });
              break;

            default:
              break;
          }
        }, 500);
        filterScrollDebounceJob();
      }
    }
  };

  const handleFilterSearch = (value, dataIndex) => {
    setFilterSearch(value);
    setFilterIsEnd(false);
    setFilterLoading(true);
    setFilterList([]);
    switch (dataIndex) {
      case 'name':
        libraries({
          variables: {
            filter: {
              ...initialTableFilter,
              limit: LIMIT,
              search: value,
            },
            sort: initialTableSort,
          },
        });
        break;

      default:
        break;
    }
  };

  const changeFilter = (newFilterObj, dataIndex) => {
    const {
      target: { value },
    } = newFilterObj;

    const filterTagsCopy = { ...filterTags };
    let newTagList;
    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filter(filterTagsCopy?.[dataIndex], (item) => {
          if (item?.label) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    } else {
      newTagList = {
        ...filterTagsCopy,
        [dataIndex]: filterTagsCopy?.[dataIndex]
          ? [...filterTagsCopy?.[dataIndex], value]
          : [value],
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const handleDeleteFilter = (value, dataIndex) => {
    let newTagList;

    if (whereFilter?.[dataIndex]?.includes(value?.key)) {
      newTagList = {
        ...filterTags,
        [dataIndex]: filter(filterTags?.[dataIndex], (item) => {
          if (item?.key) {
            return item?.key !== value?.key;
          }
          return item !== value;
        }),
      };
    }

    const updatedWhereFilter = {
      ...whereFilter,
      [dataIndex]: newTagList?.[dataIndex]?.map((item) => item?.key),
    };
    setWhereFilter(updatedWhereFilter);
    setFilterTags(newTagList);
  };

  const onRangePickerChange = (values, dataIndex) => {
    const rangeObj = {
      from: values[0]?.startOf('day'),
      to: values?.[1]?.endOf('day'),
    };

    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: rangeObj,
    };
    if (!values?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const onRadioChange = (value, dataIndex) => {
    const filtersCopy = {
      ...whereFilter,
      [dataIndex]: value,
    };
    if (!value?.length) {
      delete whereFilter?.[dataIndex];
    }
    setWhereFilter(filtersCopy);
  };

  const filterPopup = (dataIndex, showSearch = true) => ({
    filterDropdown: ({ confirm, clearFilters }) => {
      if (dataIndex === 'createdAt') {
        return (
          <div className="custom-filter-dropdown">
            <RangePicker
              allowClear={false}
              format={DATE_FORMATS?.REGULAR}
              onChange={(values) => onRangePickerChange(values, dataIndex)}
              value={
                whereFilter?.[dataIndex]?.from
                  ? [
                      whereFilter?.[dataIndex]?.from,
                      whereFilter?.[dataIndex]?.to,
                    ]
                  : null
              }
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      if (dataIndex === 'size') {
        return (
          <div className="custom-radio-filter-dropdown-large-width">
            <Group
              options={map(SIZE, (size) => size)}
              value={whereFilter?.[dataIndex]}
              onChange={(e) => onRadioChange(e?.target?.value, dataIndex)}
            />
            <Divider className="divider-filter" />
            <div className="d-flex justify-center">
              <Button
                size="small"
                className="reset-button"
                id="roles-filter-reset"
                onClick={() => handleReset(clearFilters, dataIndex, confirm)}
              >
                Reset
              </Button>
              <Button
                size="small"
                className="common-button ok-button"
                id="roles-filter-ok"
                type="primary"
                onClick={() => getFilterData(confirm, dataIndex)}
              >
                Ok
              </Button>
            </div>
          </div>
        );
      }

      return (
        <div className="custom-filter-dropdown">
          <LoaderComponent
            spinning={filterLoading}
            setHeight={35}
            size="medium"
          >
            {showSearch && (
              <SearchComponent
                className="filter-search"
                id="search-container-id-roles"
                placeholder="Search..."
                name={dataIndex}
                query={filterSearch}
                setQuery={setFilterSearch}
                getData={(value) => handleFilterSearch(value, dataIndex)}
              />
            )}
            {filterTags?.[dataIndex]?.length > 0 && (
              <div className="filter-tags-wrapper">
                {map(filterTags?.[dataIndex], (item) => (
                  <Tag
                    key={item?.key}
                    closable
                    onClose={() => handleDeleteFilter(item, dataIndex)}
                    className="filter-tag"
                  >
                    <span>{item?.label}</span>
                  </Tag>
                ))}
              </div>
            )}
            <div
              className="filter-checkboxes"
              onScroll={(e) => onFilterScroll(e, dataIndex)}
            >
              {filterList?.length > 0
                ? map(filterList, (item) => (
                    <div className="single-checkbox-div" key={item?.key}>
                      <Checkbox
                        value={item}
                        checked={whereFilter?.[dataIndex]?.includes(item?.key)}
                        key={item?.key || item}
                        onChange={(e) => changeFilter(e, dataIndex)}
                        className="single-checkbox"
                      >
                        <span>{item?.label}</span>
                      </Checkbox>
                    </div>
                  ))
                : isEmptyFilterList && (
                    <Empty image={Empty?.PRESENTED_IMAGE_SIMPLE} />
                  )}
            </div>
          </LoaderComponent>
          <Divider className="divider-filter" />
          <div className="d-flex justify-center">
            <Button
              size="small"
              className="reset-button"
              id="roles-filter-reset"
              onClick={() => handleReset(clearFilters, dataIndex, confirm)}
            >
              Reset
            </Button>
            <Button
              size="small"
              className="common-button ok-button"
              id="roles-filter-ok"
              type="primary"
              onClick={() => getFilterData(confirm, dataIndex)}
            >
              Ok
            </Button>
          </div>
        </div>
      );
    },
    filterIcon: () =>
      finalWhereFilters?.[dataIndex]?.length > 0 ||
      !isEmpty(finalWhereFilters?.[dataIndex]) ? (
        <FilterFilled className="filter-icon filter-filled" />
      ) : (
        <FilterOutlined className="filter-icon" />
      ),
    onFilterDropdownOpenChange: (visible) => {
      setFilterIndex(dataIndex);
      setFilterVisible(visible);
    },
  });

  const columns = [
    {
      title: 'LIBRARY NAME',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      width: 250,
      align: 'left',
      className: `max-width-column`,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      onCell: (record) => ({
        onClick: () =>
          isLibraryPermission &&
          record?.library?.id &&
          history?.push(
            `${ROUTES?.MASTER_LISTS}${ROUTES?.LIBRARY}${ROUTES?.BASIC_DETAILS}/view/${record?.library?.id}/${record?.library?.libraryNumber}`,
          ),
        className: `${
          isLibraryPermission && record?.library?.id && 'pointer table-cell'
        }`,
      }),
      render: (_, record) => (
        <>
          <div className="table-data-with-id">{record?.library?.name}</div>
          <div className="font-size-12">({record?.library?.libraryNumber})</div>
        </>
      ),
      ...filterPopup('name'),
    },
    {
      title: 'REQUEST ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      width: 170,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.requestNumber,
    },
    {
      title: 'QUANTITY',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      width: 100,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => record?.metaData?.quantity || '-',
    },
    {
      title: 'SIZE',
      dataIndex: 'size',
      key: 'size',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <div>
          {SIZE?.[record?.metaData?.size] ? (
            <>
              <span className="pr-8">
                {SIZE?.[record?.metaData?.size]?.label}
              </span>
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={SIZE?.[record?.metaData?.size]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setIsVisible(true);
                  setSizeImage(SIZE?.[record?.metaData?.size]?.url);
                }}
                className="pointer"
              />
            </>
          ) : (
            '-'
          )}
        </div>
      ),
      ...filterPopup('size', false),
    },
    {
      title: 'REQUEST DATE',
      dataIndex: 'requestDate',
      key: 'requestDate',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) =>
        formatDate(record?.createdAt, DATE_FORMATS?.REGULAR) || '-',
      ...filterPopup('createdAt'),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      width: 150,
      align: 'left',
      className: 'max-width-column',
      render: (_, record) => (
        <Badge
          color={REQUEST_STATUS?.[record?.status]?.color}
          text={REQUEST_STATUS?.[record?.status]?.text}
        />
      ),
      ...filterPopup('status', false),
    },
    {
      title: 'ACTIONS',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      width: 150,
      // eslint-disable-next-line no-undef
      fixed: window.innerWidth > BREAKPOINTS.desktop ? 'right' : false,
      render: (_, record) => (
        <>
          <div className="action-button">
            {isArchiveFilter ? (
              <Tooltip title="Unarchive" placement="top" zIndex={4}>
                <UnarchiveIcon
                  onClick={() => {
                    setIsArchivePrompts(true);
                    setBagReq(record);
                  }}
                  className="mr-16 pointer svg-icon"
                />
              </Tooltip>
            ) : (
              <>
                <div className="mr-16 d-flex">
                  <Tooltip
                    title="Approve"
                    placement="top"
                    className="table-action-button"
                    zIndex={4}
                  >
                    <Button
                      disabled={
                        record?.status !== REQUEST_STATUS?.PENDING?.value
                      }
                      type="link"
                      onClick={() => {
                        setIsApprovePrompts(true);
                        setBagReq(record);
                      }}
                    >
                      <ApproveIcon
                        className={
                          record?.status !== REQUEST_STATUS?.PENDING?.value
                            ? 'svg-icon-grey'
                            : 'svg-icon'
                        }
                      />
                    </Button>
                  </Tooltip>
                </div>
                <div className="mr-16 d-flex">
                  <Tooltip
                    title="Reject"
                    placement="top"
                    className="table-action-button"
                    zIndex={4}
                  >
                    <Button
                      disabled={
                        record?.status !== REQUEST_STATUS?.PENDING?.value
                      }
                      type="link"
                      onClick={() => {
                        setIsRejectPrompts(true);
                        setBagReq(record);
                      }}
                    >
                      <RejectIcon
                        className={
                          record?.status !== REQUEST_STATUS?.PENDING?.value
                            ? 'svg-icon-grey'
                            : 'svg-icon'
                        }
                      />
                    </Button>
                  </Tooltip>
                </div>
                <Tooltip title="Archive" placement="top" zIndex={4}>
                  <ArchiveIcon
                    onClick={() => {
                      setIsArchivePrompts(true);
                      setBagReq(record);
                    }}
                    className="mr-16 pointer svg-icon"
                  />
                </Tooltip>
              </>
            )}
          </div>
        </>
      ),
    },
  ]?.filter((item) => {
    if (item?.dataIndex === 'actions' && !hasUpdatePermission) {
      return item?.hidden;
    }
    return !item?.hidden;
  });

  const locale = {
    emptyText: isEmptyBagReqList ? '' : <span />,
  };

  return (
    <>
      <CommonPreview
        visible={isVisible}
        setVisible={setIsVisible}
        image={sizeImage}
        setImage={setSizeImage}
      />
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isArchivePrompts}
        onOk={() => handleArchive(bagReq?.id, bagReq?.isArchived)}
        onCancel={() => setIsArchivePrompts(false)}
        okButtonProps={{ loading: isArchiveLoading }}
      >
        Are you sure you want to
        <strong>
          {' '}
          {!bagReq?.isArchived
            ? ARCHIVING_STATE?.ARCHIVED
            : ARCHIVING_STATE?.UNARCHIVED}{' '}
        </strong>
        this request?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isApprovePrompts}
        onOk={() => handleApprove(bagReq?.id)}
        onCancel={() => setIsApprovePrompts(false)}
        okButtonProps={{ loading: isApproveLoading }}
      >
        Are you sure you want to
        <strong> Approve </strong>
        this request?
      </Modal>
      <Modal
        title="Caution"
        okText="Yes"
        cancelText="No"
        open={isRejectPrompts}
        onOk={() => handleReject(bagReq?.id)}
        onCancel={() => setIsRejectPrompts(false)}
        okButtonProps={{ loading: isRejectLoading }}
      >
        Are you sure you want to
        <strong> Reject </strong>
        this request?
      </Modal>
      <div className="d-flex justify-end mb-16 flex-wrap">
        {hasUpdatePermission && (
          <div className="mr-16 d-flex align-center">
            <h4 className="m-0 mr-8">Only Archived</h4>{' '}
            <Switch
              size="small"
              onChange={(checked) => setIsArchiveFilter(checked)}
            />
          </div>
        )}
        <SearchComponent
          className="search-box"
          query={query}
          setQuery={setQuery}
          getData={handleSearch}
        />
      </div>
      <CommonTable
        locale={locale}
        columns={columns}
        data={bagReqList || []}
        loading={isBagReqLoading}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(record) => record?.id}
      />
    </>
  );
};

export default BagsTable;
