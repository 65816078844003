import { PlusOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';
import React, { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import {
  MODULES,
  PERMISSIONS_KEY,
  PERMISSION_TYPE,
} from '../../../../common/constants';
import { hasPermission } from '../../../../common/utils';
import Portal from '../../../../components/Portal';
import CollectionsTable from '../components/CollectionsTable';

const Collections = () => {
  const {
    state: { permissions },
  } = useContext(AppContext);
  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);

  return (
    <>
      <Portal portalId="header-title-content">
        <div className="portal-header">{MODULES?.COLLECTION}</div>
      </Portal>
      <Portal portalId="header-right-content">
        {hasPermission(
          PERMISSIONS_KEY?.APP_CONTENT_COLLECTION,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCollectionsModalOpen(true);

              }}
            >
              Add New Collection
            </Button>
          )}
      </Portal>
      <Portal portalId="header-right-content-phones">
        {hasPermission(
          PERMISSIONS_KEY?.APP_CONTENT_COLLECTION,
          PERMISSION_TYPE?.CREATE,
          permissions,
        ) && (
            <Button
              className="common-button"
              size="middle"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsCollectionsModalOpen(true);
              }}
            >
              Add New Collection
            </Button>
          )}
      </Portal>
      <Card className="full-height-without-nav">
        <CollectionsTable
          isCollectionsModalOpen={isCollectionsModalOpen}
          setIsCollectionsModalOpen={setIsCollectionsModalOpen}

        />
      </Card>
    </>
  );
};

export default Collections;
