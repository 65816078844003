import { Button, Card, Image, Tag, Tooltip } from 'antd';
import React, { useState } from 'react';
import { ReactComponent as DislikeRatingIcon } from '../../../../../assets/svg/thumbs-down-solid.svg';
import { ReactComponent as LikeRatingIcon } from '../../../../../assets/svg/thumbs-up-solid.svg';
import { PRODUCT_RATE } from '../../../../../common/constants';

const AllReturnOrderItemCard = ({ order, setVisible, setToyImages }) => {
  const maxTagsToShow = 5;
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showAllAgeGroups, setShowAllAgeGroups] = useState(false);
  const toy = order?.orderItem?.toy;

  const renderedCategories = showAllCategories
    ? toy?.categories
    : toy?.categories?.slice(0, maxTagsToShow);

  const renderedAgeGroups = showAllAgeGroups
    ? toy?.ageGroups
    : toy?.ageGroups?.slice(0, maxTagsToShow);

  return (
    <Card
      className="ml-12 mr-12 mb-24 mt-12 card-with-shadow"
      title={
        <div className="overflow-scroll no-scroll-bar">{toy?.toyNumber}</div>
      }
      extra={
        <div className="d-flex align-center ml-16">
          <span>
            {order?.images?.length > 0 ? (
              <Image
                preview={{
                  visible: false,
                }}
                width={20}
                height={20}
                src={order?.images?.[0]?.url}
                onClick={(e) => {
                  e?.stopPropagation();
                  setVisible(true);
                  setToyImages(order?.images);
                }}
                className="pointer"
              />
            ) : (
              <span>No Images</span>
            )}
            {order?.images?.length > 1 ? ` + ${order?.images?.length - 1}` : ''}
          </span>
          <span className="action-button ml-16">
            {order?.productRate === PRODUCT_RATE?.LIKE ? (
              <Tooltip title="Rated" placement="top">
                <LikeRatingIcon className="mr-16 svg-icon" />
              </Tooltip>
            ) : (
              <Tooltip title="Unrated" placement="top">
                <DislikeRatingIcon className="mr-16 svg-icon" />
              </Tooltip>
            )}
          </span>
        </div>
      }
    >
      <div className="mb-8">
        <span className="mobile-card-label">Product Name: </span>
        <span>{toy?.name}</span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Categories: </span>
        <span>
          {renderedCategories?.map((category) => (
            <Tag key={category?.id}>{category?.name}</Tag>
          ))}
          {toy?.categories?.length > maxTagsToShow && !showAllCategories && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllCategories(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
      <div className="mb-8">
        <span className="mobile-card-label">Age Groups: </span>
        <span>
          {renderedAgeGroups?.map((ageGroup) =>
            ageGroup?.minAge > 18 ? (
              <Tag key={ageGroup?.id}>
                {Math.floor(ageGroup?.minAge / 12)} -{' '}
                {Math.floor(ageGroup?.maxAge / 12)} Years
              </Tag>
            ) : (
              <Tag key={ageGroup?.id}>
                {ageGroup?.minAge} - {ageGroup?.maxAge} Months
              </Tag>
            ),
          )}
          {toy?.ageGroups?.length > maxTagsToShow && !showAllAgeGroups && (
            <Button
              type="link"
              size="small"
              onClick={() => setShowAllAgeGroups(true)}
            >
              ...more
            </Button>
          )}
        </span>
      </div>
    </Card>
  );
};

export default AllReturnOrderItemCard;
