import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const COLLECTIONS_ADMIN = gql`
  query CollectionsAdmin($filter: CollectionFilter, $sort: CollectionSort) {
    collectionsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        description
        shortDescription
        publish
        order
        sortingType
        createdAt
        isArchived
        name
      }
    }
  }
`;



export const TOYS_FOR_COLLECTIONS = gql`
 query ToysForCollections($where: CollectionUniqueInput, $filter:ToysFilter, $sort: ToysSort) {
  toysForCollections(where: $where,filter: $filter, sort:$sort) {
    data {
      id
      name
    }
  }
}
`;

export const COLLECTION_TOYS = gql`
  query CollectionToys($where: CollectionUniqueInput, $filter: CollectionToyFilter, $sort: CollectionSort) {
  collectionToys(where: $where,filter: $filter, sort:$sort) {
    data {
    order
      toy {
        name
      imageUuid
      createdAt
      isArchived
      price
      url
      toyNumber
      material {
        name
        id
      }
      images {
        url
        size
        order
        key
        contentType
      }
      id
      description
      shortDescription
      eanNumber
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      facilitates {
        id
        name
      }
      ageGroups {
        maxAge
        minAge
        id
      }
      brand {
        name
        id
      }
      genres{
          id
          name
      }
      videoUrl
      }
    }
    count
  }
}
`;