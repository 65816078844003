import { Divider, Form, Image } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import { NUMBER_FIELD_TYPES, SIZE } from '../../../common/constants';
import { formValidatorRules } from '../../../common/utils';
import CommonPreview from '../../../components/CommonPreview';
import ModalComponent from '../../../components/ModalComponent';
import NumberComponent from '../../../components/NumberComponent';
import SelectComponent from '../../../components/SelectComponent';

const { Item } = Form;
const { positiveNumber } = formValidatorRules;

const RaiseBagRequestModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);
  const [sizeImage, setSizeImage] = useState();
  const [visible, setVisible] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    form?.resetFields();
  };

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={sizeImage}
        setImage={setSizeImage}
      />
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title="Packaging"
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
      >
        <Divider />
        <div className="mb-16">
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
            validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
            onFinishFailed={() => setIsValidateTrigger(true)}
          >
            <Item
              label="Quantity"
              name="quantity"
              rules={[
                { required: true, message: 'Please Enter Quantity!' },
                positiveNumber(false),
              ]}
            >
              <NumberComponent
                allowClear
                isFractional={false}
                placeholder="Enter Quantity"
                type={NUMBER_FIELD_TYPES?.STOCK}
              />
            </Item>
            <Item
              label="Size"
              name="size"
              rules={[{ required: true, message: 'Please Select Size!' }]}
            >
              <SelectComponent
                showAction={['click']}
                className="bag-size-select"
                placeholder="Select Size"
                allowClear
                options={map(SIZE, (size) => ({
                  label: (
                    <div className="bag-size-select-label">
                      <span className="mr-16">{size?.label}</span>
                      <Image
                        preview={{
                          visible: false,
                        }}
                        width={50}
                        height={50}
                        src={size?.url}
                        onClick={(e) => {
                          e?.stopPropagation();
                          setVisible(true);
                          setSizeImage(size?.url);
                        }}
                        className="pointer"
                      />
                    </div>
                  ),
                  value: size?.value,
                  labelForFilter: size?.label,
                }))}
                filterOption={(input, option) =>
                  (option?.labelForFilter ?? '')
                    ?.toLowerCase()
                    ?.includes(input?.toLowerCase())
                }
              />
            </Item>
          </Form>
        </div>
      </ModalComponent>
    </>
  );
};

export default RaiseBagRequestModal;
