import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Space, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { NUMBER_FIELD_TYPES } from '../../../../../common/constants';
import {
  formValidatorRules,
  getBase64,
  validateImage,
  validateVideo,
} from '../../../../../common/utils';
import CommonPreview from '../../../../../components/CommonPreview';
import ModalComponent from '../../../../../components/ModalComponent';
import NumberComponent from '../../../../../components/NumberComponent';
import ContentMasterSelect from './ContentMasterSelect';

const { Item } = Form;
const { TextArea } = Input;
const { whitespaceNotAllowed, required } = formValidatorRules;

const ReportDamageModal = ({
  isModalOpen,
  setIsModalOpen,
  isBtnLoading,
  onFinish,
  damageToyImage,
  setDamageToyImage,
  form,
  toyVideo,
  setToyVideo,
  toyPreviewImage,
  setToyPreviewImage,
  toyPreviewVideo,
  setToyPreviewVideo,
  order,
  setOrder,
}) => {
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [visible, setVisible] = useState(false);

  const handleToyImageChange = (info) => {
    validateImage(info, form, setDamageToyImage, 'damageToyImage');
  };

  const handleVideoChange = async (info) => {
    const res = await validateVideo(info, form, setToyVideo, 'video');
    if (res) {
      setToyPreviewVideo(
        URL.createObjectURL(info?.fileList?.[0]?.originFileObj),
      );
    }
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setToyPreviewImage(fileObj?.url || preview);
  };

  useEffect(() => {
    form?.setFieldsValue({
      includedContentMasters: order?.includedContents?.map((content) => ({
        id: content?.id,
        quantity: content?.quantity,
      })),
    });
  }, [order]);

  return (
    <>
      <CommonPreview
        visible={visible}
        setVisible={setVisible}
        image={toyPreviewImage}
        setImage={setToyPreviewImage}
      />
      <ModalComponent
        title="Report Damage"
        okText="Save"
        cancelText="Cancel"
        open={isModalOpen}
        onOk={form?.submit}
        onCancel={() => {
          setIsModalOpen(false);
          setOrder({});
          setDamageToyImage([]);
          setToyVideo([]);
          setToyPreviewVideo();
          form?.resetFields();
        }}
        confirmLoading={isBtnLoading}
        className="report-damage-modal"
      >
        <Divider />
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={() => setValidationTriggered(true)}
          validateTrigger={validationTriggered ? 'onChange' : 'onSubmit'}
        >
          <Item
            name="description"
            label="Damage Description"
            rules={[whitespaceNotAllowed]}
          >
            <TextArea
              rows={4}
              allowClear
              placeholder="Enter Damage Description"
              showCount
              maxLength={700}
            />
          </Item>
          <Item
            name="damageToyImage"
            label="Upload Images"
            rules={[{ required: true, message: 'Please Upload Images!' }]}
          >
            <Upload
              name="avatar"
              listType="picture-card"
              maxCount={4}
              fileList={damageToyImage}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleToyImageChange}
              className="avatar-uploader"
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {damageToyImage?.length !== 4 && <PlusOutlined />}
            </Upload>
          </Item>
          <Item label="Video" name="video">
            {!toyPreviewVideo && !toyVideo?.url ? (
              <Upload
                listType="picture-card"
                maxCount={1}
                fileList={toyVideo}
                beforeUpload={() => false}
                onChange={handleVideoChange}
                showUploadList={{
                  showDownloadIcon: false,
                  showPreviewIcon: false,
                  showRemoveIcon: false,
                }}
              >
                {toyVideo?.length !== 1 && <PlusOutlined />}
              </Upload>
            ) : (
              <>
                <div className="toy-video-container mb-10">
                  <video
                    width="100%"
                    height="100%"
                    controls
                    src={toyPreviewVideo || toyVideo?.url}
                    className="toy-video-upload"
                  >
                    <track kind="captions" />
                  </video>
                  <DeleteOutlined
                    className="toy-video-delete-icon"
                    onClick={() => {
                      setToyVideo([]);
                      setToyPreviewVideo();
                    }}
                  />
                </div>
              </>
            )}
          </Item>
          {order?.includedContents?.length > 0 && (
            <Item label="Content Master" className="content-master-form-list">
              <Form.List name="includedContentMasters">
                {(fields) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        align="baseline"
                        className="content-master-wrapper"
                      >
                        <Space.Compact>
                          <Form.Item
                            {...restField}
                            name={[name, 'id']}
                            rules={[
                              {
                                ...required,
                                message: 'Please Select Content Master!',
                              },
                            ]}
                          >
                            <ContentMasterSelect
                              disabled
                              contentMastersData={order?.includedContents}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'quantity']}
                            rules={[
                              {
                                required: true,
                                message: 'Please Enter Quantity!',
                              },
                            ]}
                            getValueFromEvent={(event) => {
                              const { value } = event.target;
                              const parsedValue = parseInt(value, 10);
                              return Number.isNaN(parsedValue)
                                ? 0
                                : parsedValue;
                            }}
                          >
                            <NumberComponent
                              allowClear
                              isFractional={false}
                              placeholder="Enter Quantity"
                              type={NUMBER_FIELD_TYPES?.QUANTITY}
                            />
                          </Form.Item>
                        </Space.Compact>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            </Item>
          )}
        </Form>
      </ModalComponent>
    </>
  );
};

export default ReportDamageModal;
