import { PlusOutlined } from '@ant-design/icons';
import { Divider, Form, Select, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import {
  formValidatorRules,
  getBase64,
  validateImage,
} from '../../../../common/utils';
import ModalComponent from '../../../../components/ModalComponent';

const { Item } = Form;
const { required } = formValidatorRules;
const { Option } = Select;

const BannerModal = ({
  isModalOpen,
  setIsModalOpen,
  onFinish,
  loadings,
  form,
  banner,
  setBanner,
  bannerImages,
  setBannerImages,
  setVisible,
  setBannerPreviewImage,
}) => {
  const [isValidateTrigger, setIsValidateTrigger] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
    setBanner();
    form?.resetFields();
  };

  useEffect(() => {
    form?.setFieldsValue({ name: banner?.name });
  }, [banner]);

  const handleImageChange = (info) => {
    validateImage(info, form, setBannerImages, 'image');
  };

  const handlePreview = async (fileObj) => {
    let preview;
    if (!fileObj?.url && !fileObj?.preview) {
      preview = await getBase64(fileObj?.originFileObj);
    }
    setVisible(true);
    setBannerPreviewImage(fileObj?.url || preview);
  };

  return (
    <>
      <ModalComponent
        open={isModalOpen}
        onCancel={handleCancel}
        onOk={form?.submit}
        title='Edit Banner'
        // title={banner ? 'Edit Banner' : 'Add New Banner'}
        okText="Save"
        confirmLoading={loadings}
        destroyOnClose
        forceRender
      >
        <Divider />
        <Form
          form={form}
          onFinish={(values) => {
            onFinish(values);
            setIsValidateTrigger(true);
          }}
          autoComplete="off"
          layout="vertical"
          validateTrigger={isValidateTrigger ? 'onChange' : 'onSubmit'}
          onFinishFailed={() => setIsValidateTrigger(true)}
        >
          <Item
            label="Type"
            name="name"
            rules={[{ ...required, message: 'Please Enter Name!' }]}
          >
            <Select placeholder="Select Banner Type" allowClear >
              <Option key='newPlan' value='New Plan'>New Plan</Option>
              <Option key='upgradePlan' value='Upgrade Plan'>Upgrade Plan</Option>
            </Select>
          </Item>
          <Item label="Image" name="image"
            rules={bannerImages?.length !== 1
              ? [{ required: true, message: 'Image Upload is Required!' }]
              : []
            }
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              fileList={bannerImages}
              beforeUpload={() => false}
              onPreview={handlePreview}
              onChange={handleImageChange}
              showUploadList={{
                showDownloadIcon: false,
                showPreviewIcon: true,
                showRemoveIcon: true,
              }}
            >
              {bannerImages?.length !== 1 && <PlusOutlined />}
            </Upload>
          </Item>
        </Form>
      </ModalComponent>
    </>
  );
};

export default BannerModal;
